import { Button, Card, HSpacer, Modal, Text, VSpacer } from '@/components/DesignSystem';
import { CustomerRoleChip } from '@/components/shared/CustomerRoleChip';
import { TestUserBadge } from '@/components/shared/TestUserBadge';
import { AppConfig } from '@/constants/AppConfig';
import { QueryKeys } from '@/constants/QueryKeys';
import { Routes } from '@/constants/Routes';
import { AssociatedSellersDialog } from '@/pages/Admin/FarmerList/AssociatedSellersDialog';
import {
  getCurrentRank,
  getCurrentTier,
  getFarmerRewardsProfile,
} from '@/pages/Admin/FarmerList/helper';
import { LinkedAccountsDialog } from '@/pages/Admin/FarmerList/LinkedAccountsDialog';
import { RewardsProgramStatus } from '@/pages/Admin/FarmerList/RewardsProgramStatus';
import { TransactionHistoryDialog } from '@/pages/Admin/FarmerList/TransactionHistoryDialog';
import UpdatePointsDialog from '@/pages/Admin/FarmerList/UpdatePointsDialog';
import ViewRewardsDialog from '@/pages/Admin/FarmerList/ViewRewardsDialog';
import OfferLicenses from '@/pages/ReceivePlaceOffers/OfferOverview/OfferLicenses';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { HierarchyOfRetailersApi } from '@/utilities/api/HierarchyOfRetailersApi';
import { RewardsProgramApi } from '@/utilities/api/RewardsProgramApi';
import { UserApi } from '@/utilities/api/UserApi';
import { StringUtility } from '@/utilities/String';
import { ApiRewardsProgram } from '@api/interfaces/ApiRewardsProgram';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { CircularProgress, Container, Divider, Grid, Stack } from '@mui/material';
import { AccountGroupCustomerRole, UserType } from '@shared/enums';
import { formatPhoneNumber } from '@shared/utilities';
import { getAccountName } from '@shared/utilities/UserUtilities';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomerDevicesModal } from './CustomerDevicesModal';

const CustomerDetails = () => {
  const { id: customerId = '' } = useParams();
  const [showLicensesModal, setShowLicensesModal] = useState(false);
  const [showDevicesModal, setShowDevicesModal] = useState(false);
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [showUpdatePointsModal, setShowUpdatePointsModal] = useState(false);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [showAssociatedSellersModal, setShowAssociatedSellersModal] = useState(false);
  const [showLinkedAccountsModal, setShowLinkedAccountsModal] = useState(false);
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    setShowLinkedAccountsModal(false);
  }, [customerId]);

  const { data: customer, isFetched } = useQuery(
    [QueryKeys.GET_USER, customerId],
    () => UserApi.get(customerId), {
      enabled: !!customerId,
      onError: (error: { message: string }) => {
        openSnackbar(error.message || 'An error has occurred fetching farmer');
      },
    },
  );

  const { data: farmerRewards, isFetching: isRewardsFetching } = useQuery(
    [QueryKeys.GET_FARMER_REWARDS, customerId],
    () => UserApi.getFarmerRewards(customerId),
  );

  const { data: retailer } = useQuery(
    [QueryKeys.GET_RETAILER_BY_ID, customer?.retailerId],
    () => {
      return HierarchyOfRetailersApi.getRetailerById(customer!.retailerId!);
    },
    { enabled: !!customer && !!customer.retailerId },
  );

  const isErpEnabled = retailer?.isErpEnabled ?? false;
  const isSecondaryAccount = (
    customer?.groupCustomer?.customerRole === AccountGroupCustomerRole.Secondary
  ) ?? false;

  const { data: rewardsProgram } = useQuery(
    [QueryKeys.GET_ACTIVE_PROGRAM_FOR_RETAILER, customer?.retailerId],
    () => RewardsProgramApi.getActiveProgramForRetailer(customer!.retailerId!),
    { enabled: !!customer && !!customer.retailerId },
  );

  const { data: linkedAccounts } = useQuery(
    [QueryKeys.GET_LINKED_ACCOUNTS, customerId],
    () => UserApi.getLinkedAccounts(customerId), {
      enabled: !!customer && isErpEnabled && customer.userType == UserType.Farmer,
    },
  );

  const { data: associatedSellers } = useQuery(
    [QueryKeys.GET_ASSOCIATED_SELLERS, customerId],
    () => UserApi.getAssociatedSellers(customerId), {
      enabled: !!customer && isErpEnabled && customer.userType == UserType.Farmer,
    },
  );

  if (
    !customerId
    || (isFetched && !customer)
    || (customer && customer.userType !== UserType.Farmer)
  ) {
    navigate(Routes.HOMEPAGE);
    return null;
  }

  if (!customer) {
    return <CircularProgress />;
  }

  const farmerCityState = customer.city && customer.state ? `${customer.city}, ${customer.state}` : '';

  const address = StringUtility.formatAddress(
    customer.address1,
    customer.address2,
    customer.city,
    customer.state,
    customer.postal,
  ) || '-';
  const phone = customer.telephone ? formatPhoneNumber(customer.telephone, 'paren') : '-';
  const campaignImageUrl = rewardsProgram?.campaignImage && (
    `${AppConfig.staticImageHost}/${rewardsProgram.campaignImage}`
  );
  const showRewardsProgramStatus = (
    isErpEnabled && !!rewardsProgram && !!retailer && !!farmerRewards && !isSecondaryAccount
  );

  const Header = () => {
    if (!retailer) {
      return null;
    }
    return (
      <Stack
        alignItems="center"
        bgcolor="#EBEBEB"
        direction="row"
        height="36px"
        justifyContent="center"
      >
        {!!campaignImageUrl && (
          <Stack alignItems="center" direction="row">
            <img
              height="20px"
              src={campaignImageUrl}
              style={{ objectFit: 'contain' }}
              width="20px"
            />
            <HSpacer size="3" />
          </Stack>
        )}
        <Text
          category="label-medium"
          style={{ color: '#141414' }}
        >
          {retailer.name} customer account
        </Text>
      </Stack>
    );
  };

  const farmerReward = farmerRewards?.find(
    (rewards) => rewards.programId === rewardsProgram?.id,
  ) ?? null;
  const rewardsProfile = getFarmerRewardsProfile(farmerReward, rewardsProgram ?? null);

  const getCurrentTierName = (rewardsProgram: ApiRewardsProgram) => {
    const currentRank = getCurrentRank(rewardsProfile, rewardsProgram);
    const currentTierName = !rewardsProfile || currentRank === null
      ? 'Not yet started'
      : getCurrentTier(currentRank, rewardsProgram?.tiers ?? [])?.name;
    return currentTierName;
  };

  const accountName = getAccountName(customer);

  return (
    <Container maxWidth='lg'>
      <VSpacer size='4' />
      <Header />
      <VSpacer size='5' />
      <Button
        color="inherit"
        onClick={() => navigate(-1)}
        startIcon={<KeyboardBackspaceIcon />}
        sx={{ display: 'flex', align: 'center' }}
        testID='back-to-customers-button'
        variant='text'
      >
        Back to Customers
      </Button>
      <VSpacer size='5' />
      <Grid container spacing={2}>
        <Grid item m={0} md={4} sm={12}>
          <Card testID="farmer-details-card">
            {!!customer.businessName && customer.businessName !== accountName && (
              <Text category="overline" textTransform="uppercase">
                {customer.businessName}
              </Text>
            )}
            <Text breakWord category="headline-medium">
              {accountName}
            </Text>
            {isErpEnabled && !!rewardsProgram && !isSecondaryAccount && (
              <>
                <VSpacer size="3" />
                <Text category="body-xlarge">
                  {rewardsProgram.name} status: {getCurrentTierName(rewardsProgram)}
                </Text>
              </>
            )}
            {(!!customer.groupCustomer || customer.isTest) && (
              <VSpacer size="4" />
            )}
            <Stack direction="row">
              {!!customer.groupCustomer && (
                <CustomerRoleChip customerRole={customer.groupCustomer.customerRole} />
              )}
              {!!customer.groupCustomer && customer.isTest && (
                <HSpacer size="3" />
              )}
              {customer.isTest && (
                <TestUserBadge />
              )}
            </Stack>
          </Card>
          <VSpacer size="5" />
          <Card testID="farmer-profile-card">
            <Stack>
              <Text category="label-medium">Address</Text>
              <Text category="body-medium" multiline>{address}</Text>
              <VSpacer size="7" />
            </Stack>
            <Stack>
              <Text category="label-medium">Phone</Text>
              <Text category="body-medium">{phone}</Text>
              <VSpacer size="7" />
            </Stack>
            <Stack>
              <Text category="label-medium">Email</Text>
              <Text
                category="body-medium"
                sx={{
                  overflowWrap: 'break-word',
                }}
              >
                {customer.email || '-'}
              </Text>
              <VSpacer size="7" />
            </Stack>
            <Stack>
              <Text category="label-medium">ERP ID</Text>
              <Text category="body-medium">{customer.externalId || '-'}</Text>
              <VSpacer size="7" />
            </Stack>
            <Stack direction="row">
              <Button
                onClick={() => setShowLicensesModal(true)}
                size="small"
                testID="view-licenses-button"
                variant="text"
              >
                View licenses
              </Button>
              <Button
                onClick={() => setShowDevicesModal(true)}
                size="small"
                testID="view-devices-button"
                variant="text"
              >
                View devices
              </Button>
            </Stack>
          </Card>
        </Grid>
        <Grid item md={8} sm={12}>
          <Card testID={`${customer.id}-rewards-details-card`}>
            {showRewardsProgramStatus && (
              <>
                <RewardsProgramStatus
                  customer={customer}
                  farmerRewards={farmerRewards}
                  isRewardsFetching={isRewardsFetching}
                  retailer={retailer}
                  rewardsProgram={rewardsProgram}
                />
                <VSpacer size="6" />
                <Divider />
              </>
            )}
            {!isSecondaryAccount && (
              <>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  my="20px"
                >
                  <Text category="body-large">Wallet</Text>
                  <Stack alignItems="center" direction="row">
                    <Button
                      onClick={() => setShowUpdatePointsModal(true)}
                      testID="update-points-button"
                      variant="text"
                    >
                      Update points
                    </Button>
                    <HSpacer size="4" />
                    <Button
                      color="inherit"
                      onClick={() => setShowWalletModal(true)}
                      testID="view-wallet-button"
                      variant="outlined"
                    >
                      View
                    </Button>
                  </Stack>
                </Stack>
                <Divider />
              </>
            )}
            <Stack alignItems="center" direction="row" justifyContent="space-between" my="20px">
              <Text category="body-large">Transaction history</Text>
              <Button
                color="inherit"
                onClick={() => setShowTransactionModal(true)}
                testID="view-wallet-button"
                variant="outlined"
              >
                View
              </Button>
            </Stack>
            {isErpEnabled && (
              <>
                <Divider />
                <Stack alignItems="center" direction="row" justifyContent="space-between" my="20px">
                  <Text category="body-large">Associated sellers</Text>
                  <Button
                    color="inherit"
                    onClick={() => setShowAssociatedSellersModal(true)}
                    testID="view-associated-sellers-button"
                    variant="outlined"
                  >
                    View
                  </Button>
                </Stack>
              </>
            )}
            {isErpEnabled && (
              <>
                <Divider />
                <Stack alignItems="center" direction="row" justifyContent="space-between" my="20px">
                  <Text category="body-large">Linked accounts</Text>
                  <Button
                    color="inherit"
                    onClick={() => setShowLinkedAccountsModal(true)}
                    testID="view-linked-accounts-button"
                    variant="outlined"
                  >
                    View
                  </Button>
                </Stack>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
      {showLicensesModal && (
        <Modal
          cancelButton={(props) => (
            <Button
              onClick={() => setShowLicensesModal(false)}
              {...props}
              variant="text"
            >
              Close
            </Button>
          )}
          onClose={() => setShowLicensesModal(false)}
          open
          subline={[getAccountName(customer), farmerCityState].filter(Boolean).join(' | ')}
          testID='farmer-license-modal'
          title='License numbers'
        >
          <OfferLicenses user={customer}/>
        </Modal>
      )}
      <CustomerDevicesModal
        customerId={customerId}
        onClose={() => setShowDevicesModal(false)}
        open={showDevicesModal}
      />
      {showWalletModal && (
        <ViewRewardsDialog
          customer={customer}
          retailer={retailer}
          setShow={setShowWalletModal}
          show
        />
      )}
      {showUpdatePointsModal && (
        <UpdatePointsDialog
          farmer={customer}
          setShow={setShowUpdatePointsModal}
          show
        />
      )}
      <TransactionHistoryDialog
        customer={customer}
        onClose={() => setShowTransactionModal(false)}
        show={showTransactionModal}
      />
      <AssociatedSellersDialog
        associatedSellers={associatedSellers ?? []}
        onClose={() => setShowAssociatedSellersModal(false)}
        show={showAssociatedSellersModal}
      />
      <LinkedAccountsDialog
        linkedAccounts={linkedAccounts ?? []}
        onClose={() => setShowLinkedAccountsModal(false)}
        show={showLinkedAccountsModal}
      />
    </Container>
  );
};

export default CustomerDetails;
