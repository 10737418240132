import defaultLocationImage from '@/assets/images/default-location-image.png';
import { HSpacer, Switch, Text, VSpacer } from '@/components/DesignSystem';
import { Avatar } from '@/components/DesignSystem/Avatar/Avatar';
import { AppConfig } from '@/constants/AppConfig';
import { ConfirmationDialog } from '@/constants/constant';
import { stringifyCount, TOGGLE_LOCATION_POP_UP } from '@/constants/HierarchyOfRetailers';
import { Routes } from '@/constants/Routes';
import { useEditRetailerLocation } from '@/hooks/useRetailerLocations';
import { ApiRetailerLocation } from '@api/interfaces';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Card, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmDialog from '../ConfirmDialog';
import { CustomTypography } from '../CustomStyledComponents';

const LocationOverviewCard = ({
  isRetailerActive,
  location,
}: {
  isRetailerActive: boolean;
  location: ApiRetailerLocation;
}) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [changeIsActive, setChangeIsActive] = useState(false);

  const { updateRetailerLocation, isLoading } = useEditRetailerLocation(
    location.id ?? '',
    setChangeIsActive,
  );

  const detailsNavigator = () => {
    navigate(
      `${Routes.ADMIN_HIERARCHY_OF_RETAILERS}/${id}/location/${location.id}`,
    );
  };

  const logoSrc = location.image && `${AppConfig.staticImageHost}/${location.image}`;

  return (
    <Card
      onClick={detailsNavigator}
      sx={{
        cursor: 'pointer',
        padding: '20px',
      }}
    >
      <Stack
        alignItems={'center'}
        direction='row'
        justifyContent={'space-between'}
      >
        <Stack direction='row' spacing={2}>
          <Avatar
            alt={`${location.name}-logo`}
            height={56}
            src={logoSrc ?? defaultLocationImage}
            width={56}
          />
          <Stack ml={'24px !important'} spacing={1}>
            <CustomTypography
              color={'#C9C6BE'}
              fontSize={'11px'}
              fontWeight={'500'}
              letterSpacing={'1.25px'}
              textTransform={'uppercase'}
            >
              ID: {location.id}
            </CustomTypography>
            <CustomTypography textTransform={'capitalize'}>{location.name}</CustomTypography>
            <CustomTypography
              fontSize={'14px'}
              letterSpacing={'0.25px'}
              lineHeight={'20px'}
            >
              {stringifyCount(location.salespersons?.length, 'member', 'members')}
            </CustomTypography>
          </Stack>
        </Stack>
        <Stack>
          <Switch
            checked={location.isActive}
            label={location.isActive ? 'Active' : 'Inactive'}
            labelPlacement='start'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setChangeIsActive(true);
            }}
            sx={{ marginRight: '0px' }}
            testID='active-toggle'
          />
          {location.isPreferred &&
            <>
              <VSpacer size="4" />
              <Stack direction="row">
                <VerifiedIcon color="success" />
                <HSpacer size="3" />
                <Text color="success">Preferred Retailer</Text>
              </Stack>
            </>
          }
        </Stack>
      </Stack>
      {changeIsActive && isRetailerActive && (
        <ConfirmDialog
          isLoading={isLoading}
          isOpen={changeIsActive}
          message={
            location.isActive
              ? ConfirmationDialog.inActivateLocationMessage
              : ConfirmationDialog.activateLocationMessage
          }
          onClose={() => setChangeIsActive(false)}
          onConfirm={() => {
            updateRetailerLocation({ isActive: !location.isActive });
          }}
          title={
            location.isActive
              ? ConfirmationDialog.inActivateLocationTitle
              : ConfirmationDialog.activateLocationTitle
          }
        />
      )}
      {
        changeIsActive && !isRetailerActive && (
          <ConfirmDialog
          cancelButtonText='Close'
          isOpen={changeIsActive}
          message={TOGGLE_LOCATION_POP_UP}
          onClose={() => setChangeIsActive(false)}
        />
        )
      }
    </Card>
  );
};

export default LocationOverviewCard;
