import { Button, Text, VSpacer } from '@/components/DesignSystem';
import { Routes } from '@/constants/Routes';
import { getFarmerRewardsProfile } from '@/pages/Admin/FarmerList/helper';
import { RewardsProgramProgressModal } from '@/pages/Admin/FarmerList/RewardsProgramProgressModal';
import { RewardsProgramStatusCards } from '@/pages/Admin/FarmerList/RewardsProgramStatusCards';
import { ApiRetailer, ApiUser } from '@api/interfaces';
import { ApiFarmerRewards } from '@api/interfaces/ApiFarmerRewards';
import { ApiRewardsProgram } from '@api/interfaces/ApiRewardsProgram';
import { Leaderboard } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { AppConfig } from '@/constants/AppConfig';
import { SharedConfig } from '@shared/SharedConfig';

interface RewardsProgramStatusProps {
  customer: ApiUser,
  farmerRewards: ApiFarmerRewards[],
  isRewardsFetching: boolean,
  retailer: ApiRetailer,
  rewardsProgram: ApiRewardsProgram,
}

export const RewardsProgramStatus = ({
  customer,
  farmerRewards,
  isRewardsFetching,
  retailer,
  rewardsProgram,
}: RewardsProgramStatusProps) => {

  const [showProgressModal, setShowProgressModal] = useState(false);

  const farmerReward = farmerRewards.find(
    (rewards) => rewards.programId === rewardsProgram.id,
  ) ?? null;

  const rewardsProfile = getFarmerRewardsProfile(farmerReward, rewardsProgram);
  const earningText = `${rewardsProfile !== null ? 're-' : ''}earning`;

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Text category="body-xlarge">
            Rewards program
          </Text>
          <Text category="body-medium">
            Progress towards {earningText} your loyalty status
          </Text>
        </Stack>
        {!!customer.dashboardAccessToken && !!farmerReward && !!retailer.shortUrlName && (
          <Button
            color="inherit"
            onClick={() => {
              let domain;
              if (AppConfig.env.devel) {
                domain = `${retailer.shortUrlName}.devel.${SharedConfig.shortUrlDomain}`;
              } else if (AppConfig.env.staging) {
                domain = `${retailer.shortUrlName}.staging.${SharedConfig.shortUrlDomain}`;
              } else {
                domain = `${retailer.shortUrlName}.${SharedConfig.shortUrlDomain}`;
              }
              window.open(
                `https://${domain}/#${Routes.CUSTOMER_DASHBOARD_ALT.replace(
                  ':accessToken',
                  customer.dashboardAccessToken!,
                )}`,
                '_blank',
              );
            }}
            testID="customer-dashboard-button"
            variant="outlined"
          >
            Customer dashboard
          </Button>
        )}
      </Stack>
      <VSpacer size="5" />
      <RewardsProgramStatusCards
        customerId={customer.id}
        farmerRewards={farmerReward}
        isRewardsFetching={isRewardsFetching}
        rewardsProgram={rewardsProgram}
      />
      <VSpacer size="3" />
      <Stack alignItems="flex-end">
        <Button
          onClick={() => setShowProgressModal(true)}
          startIcon={<Leaderboard />}
          testID="view-progress-button"
          variant="text"
        >
          View progress
        </Button>
      </Stack>
      <RewardsProgramProgressModal
        customerId={customer.id}
        farmerRewards={farmerReward}
        isRewardsFetching={isRewardsFetching}
        onClose={() => setShowProgressModal(false)}
        open={showProgressModal}
        rewardsProgram={rewardsProgram}
      />
    </Stack>
  );
};
