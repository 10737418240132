import { Avatar as MuiAvatar } from '@mui/material';
import { AvatarProps as MuiAvatarProps } from '@mui/material';

interface AvatarProps extends MuiAvatarProps {
  height?: number,
  width?: number,
}

export const Avatar = ({
  children,
  height,
  src,
  sx,
  variant,
  width,
  ...props
}: AvatarProps) => {
  return (
    <MuiAvatar
      {...props}
      {...((!variant || variant === 'circular')
        ? { imgProps: { style: { maxHeight: '70%', maxWidth: '70%', objectFit: 'contain' } } }
        : {}
      )}
      src={src}
      sx={{
        backgroundColor: src ? 'white' : '#EBEBEB',
        height,
        width,
        ...sx,
      }}
      variant={variant}
    >
      {children}
    </MuiAvatar>
  );
};
