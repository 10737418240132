import { Box, Grid, Stack } from "@mui/material";
import { Card, IconButton, Text, VSpacer } from "@/components/DesignSystem";
import React from "react";
import Edit from '@mui/icons-material/Edit';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Add from '@mui/icons-material/Add';

interface PropertyCardGridItemProps {
  color?: string | null,
  statusText?: string | null,
  onAddClicked: () => void,
  onEditClicked?: () => void,
  onRemoveClicked?: () => void,
  title: string,
}

export const PropertyCardGridItem = ({
  color,
  statusText,
  onAddClicked,
  onEditClicked,
  onRemoveClicked,
  title,
}: PropertyCardGridItemProps) => {
  return (
    <Grid item sm={4} xs={12}>
      <Card
        cardContentStyle={{
          '&:last-child': {
            paddingBottom: '16px',
          },
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between',
          padding: '16px 12px 16px 16px',
          width: '100%',
        }}
        sx={{ height: '140px' }}
        testID={`${title}-card`}
      >
        <Stack>
          <Text category="title-medium">
            {title}
          </Text>
          {!!statusText && (
            <>
              <VSpacer size="2" />
              <Stack alignItems="center" direction="row" gap="4px">
                {!!color && (
                  <Stack alignItems="center" height="24px" justifyContent="center" width="24px">
                    <Box bgcolor={color} borderRadius="2px" height="14px" width="14px" />
                  </Stack>
                )}
                <Text
                  category="body-medium"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {statusText}
                </Text>
              </Stack>
            </>
          )}
        </Stack>
        <Stack alignItems="center" direction="row" justifyContent="end">
          {(statusText && !!onEditClicked) && (
            <IconButton onClick={onEditClicked} testID={`${title}-edit-button`}>
              <Edit />
            </IconButton>
          )}
          {(statusText && !!onRemoveClicked) && (
            <IconButton onClick={onRemoveClicked} testID={`${title}-remove-button`}>
              <DeleteOutline />
            </IconButton>
          )}
          {!statusText && (
            <IconButton color="primary" onClick={onAddClicked} testID={`${title}-add-button`} variant="filled">
              <Add />
            </IconButton>
          )}
        </Stack>
      </Card>
    </Grid>
  );
};