import {
  Button,
  Card,
  Dialog,
  HSpacer,
  IconButton,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { AppConfig } from '@/constants/AppConfig';
import { QueryKeys } from '@/constants/QueryKeys';
import { getGenericImage, Wallet } from '@/pages/Admin/FarmerList/helper';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { LoyaltySkuApi } from '@/utilities/api/LoyaltySkuApi';
import { ProductApi } from '@/utilities/api/ProductApi';
import { PromotionApi } from '@/utilities/api/PromotionApi';
import { UserApi } from '@/utilities/api/UserApi';
import { ApiProduct, ApiPromotion, ApiRetailer, ApiUser } from '@api/interfaces';
import { ContentCut } from '@mui/icons-material';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Star from '@mui/icons-material/Star';
import { CircularProgress, Divider, List, ListItem, Stack } from '@mui/material';
import { formatCurrency, formatDate, groupBy } from '@shared/utilities';
import { getAccountName } from '@shared/utilities/UserUtilities';
import React, { Dispatch, Fragment, ReactNode, SetStateAction, useState } from 'react';
import { useQuery } from 'react-query';
import { Avatar } from '@/components/DesignSystem/Avatar/Avatar';
import GrowersIcon from "@/assets/icons/growers-icon.svg";

type ViewRewardsDialogProps = {
  customer: ApiUser,
  retailer: ApiRetailer | undefined,
  show: boolean,
  setShow: Dispatch<SetStateAction<boolean>>,
}

const ViewRewardsDialog = ({
  customer,
  retailer,
  show,
  setShow,
} : ViewRewardsDialogProps) => {
  const [promotions, setPromotions] = useState<ApiPromotion[]>([]);
  const [products, setProducts] = useState<ApiProduct[]>([]);
  const [dialogOverline, setDialogOverline] = useState<string>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogText, setDialogText] = useState<string | ReactNode>('');
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const { openSnackbar } = useSnackbar();

  const { data: rewards, isLoading } = useQuery(
    [QueryKeys.GET_USER_LOYALTY_BALANCES, customer.id],
    async () => {
      const results = await UserApi.getLoyaltyBalances(customer.id);
      if (results.subledgerBalances) {
        const promotionIds = Object.values(results.subledgerBalances).map(
          (balance) => balance.promotionId,
        ).filter(Boolean) as string[];
        if (promotionIds.length) {
          const promotions = await PromotionApi.list({ ids: promotionIds });
          setPromotions(promotions.data);
          const productIds = promotions.data.flatMap(
            (promotion) => promotion.productIdsForRedeemingPoints,
          );
          if (productIds.length) {
            const products = await ProductApi.productListData({ id: productIds });
            setProducts(products.data);
          }
        }
      }
      return results;
    },
  );

  const allUpdatedAtDates = [customer.updatedAt];
  if (rewards) {
    if (rewards.subledgerBalances) {
      Object.values(rewards.subledgerBalances).forEach(
        (balance) => allUpdatedAtDates.push(balance.updatedAt),
      );
    }
  }
  const lastUpdatedDate = new Date(Math.max(...allUpdatedAtDates.map((date) => date.getTime())));

  const { data: loyaltySkus } = useQuery(
    [QueryKeys.GET_LOYALTY_SKUS, retailer],
    () => LoyaltySkuApi.list({ retailerId: retailer!.id }),
    { enabled: !!retailer },
  );

  const getRetailerWallets = () => {
    let wallets: Wallet[] = [];
    if (rewards?.subledgerBalances) {
      wallets = Object.values(rewards.subledgerBalances).filter(
        (wallet) => !!wallet.retailerId
          && !wallet.manufacturerId
          && wallet.retailerId === retailer?.id,
      );
    }
    wallets.sort((a, b) => {
      if (a.promotionId === null && b.promotionId !== null) return -1;
      if (a.promotionId !== null && b.promotionId === null) return 1;
      return a.name.localeCompare(b.name);
    });
    return wallets;
  };

  const getGroupedManufacturerWallets = () => {
    let wallets: Wallet[] = [];
    if (rewards?.subledgerBalances) {
      wallets = Object.values(rewards.subledgerBalances).filter(
        (wallet) => !!wallet.retailerId && !!wallet.manufacturerId,
      );
    }
    wallets.sort((a, b) => {
      if (a.promotionId === null && b.promotionId !== null) return -1;
      if (a.promotionId !== null && b.promotionId === null) return 1;
      return a.name.localeCompare(b.name);
    });
    return groupBy(wallets, (wallet) => wallet.manufacturerId!);
  };

  const getLoyaltySkus = (retailerId: string, manufacturerId: string | null) => {
    return loyaltySkus?.filter(
      (sku) => sku.retailerId === retailerId && sku.manufacturerId === manufacturerId,
    ).map((sku) => sku.product?.externalId).filter(Boolean) as string[] ?? [];
  };

  const sortByManufacturerName = (
    a: string,
    b: string,
    wallets: { [manufacturerId: string] : Wallet[] },
  ): number => {
    return wallets[a][0].manufacturerName!.localeCompare(wallets[b][0].manufacturerName!);
  };

  const renderWallets = () => {
    const groupedWallets: Wallet[][] = [];
    const retailerWallets = getRetailerWallets();
    if (retailerWallets.length) {
      groupedWallets.push(retailerWallets);
    }
    const groupManufacturerWallets = getGroupedManufacturerWallets();

    const sortedManufacturerIdKeys = Object.keys(groupManufacturerWallets).sort(
      (a, b) => sortByManufacturerName(a, b, groupManufacturerWallets),
    );
    sortedManufacturerIdKeys.forEach((id) => {
      groupedWallets.push(groupManufacturerWallets[id]);
    });
    const growersWallet: Wallet = {
      image: GrowersIcon,
      points: rewards?.growersBalance?.points ?? 0,
      promotionId: null,
      retailerId: null,
      retailerName: null,
      name: 'GROWERS',
      manufacturerId: null,
      manufacturerName: null,
    };
    if (!retailer?.isErpEnabled) {
      groupedWallets.push([growersWallet]);
    }
    return groupedWallets.map((wallets, index) => {
      return (
        <Fragment key={wallets[0].name}>
          <Card testID={`${wallets[0].name}-wallet-card`}>
            <Stack direction="row">
              <Avatar
                alt={`${retailer?.name} logo`}
                imgProps={{ sx: { objectFit: 'contain' } }}
                src={
                  wallets[0].image
                    ? `${AppConfig.staticImageHost}/${wallets[0].image}`
                    : getGenericImage(wallets[0].retailerId, wallets[0].manufacturerId)
                }
                sx={{
                  height: '44px',
                  width: retailer?.image ? undefined : '60px',
                }}
                variant="square"
              />
              <HSpacer size="5" />
              <Stack>
                <Text category="title-large">
                  {wallets[0].manufacturerName ?? wallets[0].retailerName ?? wallets[0].name}
                </Text>
                <Stack alignItems="center" direction="row">
                  <Star sx={{ color: '#F1BC42', height: '14px', width: '14px' }} />
                  <HSpacer size="2" />
                  <Text category="body-medium">
                    {wallets.reduce((acc, wallet) => acc + wallet.points, 0)} points
                  </Text>
                </Stack>
              </Stack>
            </Stack>
            <VSpacer size="6" />
            <Divider />
            {wallets.map((wallet) => (
              <Fragment key={`${wallet.retailerId}-${wallet.name}`}>
                <VSpacer size="5" />
                <Stack alignItems="center" direction="row" justifyContent="space-between">
                  <Text category="body-medium">
                    {wallet.promotionId ? wallet.name : 'All products'}
                  </Text>
                  <Stack alignItems="center" direction="row">
                    <Text category="body-medium" flexShrink={0} textAlign="right">
                      {formatCurrency(wallet.points)} available
                    </Text>
                    <HSpacer size="3" />
                    <IconButton
                      onClick={() => {
                        const { retailerName } = wallet;
                        setDialogTitle(wallet.promotionId ? wallet.name : 'All products');
                        setDialogOverline(retailerName ? retailerName : 'GROWERS');
                        if (wallet.promotionId) {
                          const promotion = promotions.find(
                            (promotion) => promotion.id === wallet.promotionId,
                          );
                          if (promotion) {
                            const isAnyProducts = !promotion.productIdsForRedeemingPoints.length;
                            if (isAnyProducts) {
                              setDialogText(
                                `Points can be redeemed on any product sold by ${retailerName}.`,
                              );
                            } else {
                              const productsList = products.filter((product) => (
                                promotion.productIdsForRedeemingPoints.includes(product.id)
                              )).map(
                                (product) => <ListItem key={product.id}>{product.name}</ListItem>,
                              );
                              setDialogText(
                                <Stack>
                                  <Text>Products that points can be redeemed on</Text>
                                  <List>
                                    {productsList}
                                  </List>
                                </Stack>,
                              );
                            }
                          }
                        } else if (wallet.manufacturerId) {
                          setDialogText(`Points can be redeemed on any ${wallet.manufacturerName} product sold by ${retailerName}.`);
                        } else if (wallet.retailerId) {
                          setDialogText(`Points can be redeemed on any product sold by ${retailerName}.`);
                        } else {
                          setDialogText(`Points can be redeemed on any product.`);
                        }
                        setShowDialog(true);
                      }}
                      testID={`${wallet.retailerId}-${wallet.name}-icon-button`}
                    >
                      <ArrowDropDown />
                    </IconButton>
                  </Stack>
                </Stack>
                <VSpacer size="5" />
                <Divider />
              </Fragment>
            ))}
            {!!wallets[0].retailerId && (
              <Stack direction="row" flexWrap="wrap">
                {getLoyaltySkus(wallets[0].retailerId!, wallets[0].manufacturerId).map((sku) => (
                  <Fragment key={sku}>
                    <Stack>
                      <VSpacer size="5" />
                      <Stack
                        alignItems="center"
                        direction="row"
                        onClick={() => {
                          void navigator.clipboard.writeText(sku);
                          openSnackbar('Copied');
                        }}
                        px="24px"
                        py="12px"
                        sx={{
                          borderRadius: '100px',
                          border: '1px dashed #D8D8D8',
                          cursor: 'pointer',
                        }}
                        width="fit-content"
                      >
                        <ContentCut sx={{ height: '16px', width: '16px' }}/>
                        <HSpacer size="3" />
                        <Text category="body-medium" whiteSpace="nowrap">
                          Loyalty SKU: {sku}
                        </Text>
                      </Stack>
                    </Stack>
                    <HSpacer size="4" />
                  </Fragment>
                ))}
              </Stack>
            )}
          </Card>
          {index !== groupedWallets.length - 1 && <VSpacer size="5" />}
        </Fragment>
      );
    });
  };

  return (
    <>
      <Dialog
        acceptButton={(props) => (
          <Button
            {...props}
            onClick={() => setShow(false)}
            variant="contained"
          >
            Close
          </Button>
        )}
        dialogWidth="560px"
        onClose={() => setShow(false)}
        open={show}
        showCloseButton={false}
        testID="view-rewards-dialog"
        title="Wallet"
      >
        {isLoading &&
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        }
        <Stack alignItems="baseline" direction="row" justifyContent="space-between">
          <Text category="body-large">{getAccountName(customer)}</Text>
          <Text category="body-small" flexShrink={0}>
            Updated {formatDate(lastUpdatedDate)}
          </Text>
        </Stack>
        <VSpacer size="5" />
        {renderWallets()}
      </Dialog>
      {showDialog && (
        <Dialog
          acceptButton={(props) => (
            <Button
              {...props}
              onClick={() => setShowDialog(false)}
              variant="contained"
            >
              Close
            </Button>
          )}
          onClose={() => setShowDialog(false)}
          open={showDialog}
          showCloseButton={false}
          testID="wallet-description-dialog"
        >
          <Text category="overline">{dialogOverline}</Text>
          <VSpacer size="2" />
          <Text category="title-large">{dialogTitle}</Text>
          <VSpacer size="3" />
          <Text category="title-small">{dialogText}</Text>
        </Dialog>
      )}
    </>
  );
};

export default ViewRewardsDialog;
