import {
  Button,
  Checkbox,
  HSpacer,
  MenuItem,
  NumericInput,
  SegmentedButton,
  Select,
  Text,
  VSpacer,
} from "@/components/DesignSystem";
import Check from "@mui/icons-material/Check";
import { Stack } from "@mui/material";
import { RewardsType } from "@shared/enums/RewardsType";
import { formatRewardsUoM, RewardsUom, RewardsUomMapping } from "@shared/enums/RewardsUom";
import { SharedConfig } from "@shared/SharedConfig";
import { round } from 'lodash';
import React, { useState } from 'react';

interface RewardsLoyaltyValueInputProps {
  categoryName?: string,
  disableUomSelector?: boolean,
  disabled?: boolean,
  hideOnDashboard?: boolean,
  innerContainerWidth?: string,
  onClickHideOnDashboard?: (value: boolean) => void,
  onUpdate: (updates: {
    hideOnDashboard?: boolean,
    rewardType?: RewardsType,
    uom?: RewardsUom,
    value?: number,
  }) => void,
  rewardType?: RewardsType,
  testID: string,
  uom?: RewardsUom,
  value?: number,
  width?: string,
}

export const RewardsLoyaltyValueInput = ({
  categoryName,
  disableUomSelector,
  disabled,
  hideOnDashboard,
  innerContainerWidth = '100%',
  onClickHideOnDashboard,
  onUpdate,
  rewardType,
  testID,
  uom,
  value,
  width = '643px',
}: RewardsLoyaltyValueInputProps) => {
  const { rewardsMaxRewardPercent } = SharedConfig.settings;

  const [isFixed, setIsFixed] = useState(uom === RewardsUom.Dollars);

  const UOMs = Object.keys(RewardsUomMapping).filter(
    uom => uom !== RewardsUom.Dollars) as RewardsUom[];

  return (
    <Stack width={width}>
      {!!categoryName && (
        <>
          <Text category="title-medium">
            {categoryName}
          </Text>
          <VSpacer size="5" />
        </>
      )}
      <Stack alignSelf="center" width={innerContainerWidth}>
        <Text category="title-small">
          Points are earned based on:
        </Text>
        <VSpacer size="2"/>
        <SegmentedButton
          color="primary"
          disabled={disabled}
          fullWidth
          selectedIndex={isFixed ? 0 : 1}
        >
          <Button
            aria-selected={isFixed}
            key="Fixed"
            onClick={() => {
              onUpdate({
                uom: RewardsUom.Dollars,
                rewardType: RewardsType.Dollars,
                value: undefined,
              });
              setIsFixed(true);
            }}
            startIcon={isFixed && <Check sx={{ width: "17px", height: "17px" }} />}
            testID={`${testID}-fixed-button`}
          >
            Dollar amount spent
          </Button>
          <Button
            aria-selected={!isFixed}
            key="Proportional"
            onClick={() => {
              onUpdate({
                hideOnDashboard,
                rewardType: RewardsType.Dollars,
                uom: undefined,
                value: undefined,
              });
              setIsFixed(false);
            }}
            startIcon={!isFixed && <Check sx={{ width: "17px", height: "17px" }} />}
            testID={`${testID}-proportional-button`}
          >
            Qty per uom purchased
          </Button>
        </SegmentedButton>
        <VSpacer size="10" />
        <Stack alignItems="center" bgcolor="#1c1c1c" p="20px 24px 32px 24px">
          {isFixed && (
            <Stack>
              <Text category="body-large">
                For every $1.00 spent, customer will receive
              </Text>
              <VSpacer size="5" />
              <Stack alignItems="center" alignSelf="center" direction="row">
                <Select
                  onChangeValue={(rewardsType) => (
                    onUpdate({
                      hideOnDashboard,
                      rewardType: rewardsType as RewardsType,
                      uom,
                      value: rewardsType === RewardsType.Dollars
                        ? value && round(value / 100, 4)
                        : value && round(
                          Math.min(value * 100, rewardsMaxRewardPercent * 100), 5,
                        ),
                    })
                  )}
                  testID={`${testID}-rewards-type`}
                  value={rewardType}
                  width={68}
                >
                  <MenuItem key="%" testID={`${testID}-rewards-type-percent}`} value="%">
                    %
                  </MenuItem>
                  <MenuItem key="$" testID={`${testID}-rewards-type-dollars}`} value="$">
                    $
                  </MenuItem>
                </Select>
                <HSpacer size="3" />
                <NumericInput
                  decimals={rewardType === RewardsType.Dollars ? 4 : 3}
                  maxValue={rewardType === RewardsType.Percent
                    ? rewardsMaxRewardPercent * 100
                    : undefined}
                  minValue={0}
                  onChangeNumber={(value) => onUpdate({
                    hideOnDashboard,
                    rewardType,
                    uom,
                    value: rewardType === RewardsType.Percent
                      ? value && Math.min(value, rewardsMaxRewardPercent * 100)
                      : value,
                  })}
                  placeholder={rewardType === RewardsType.Dollars ? "0.0000" : "0.000"}
                  testID={`${testID}-fixed-rewards-value`}
                  value={value}
                  width="112px"
                />
                <HSpacer size="4" />
                <Text category="body-large">
                  in points.
                </Text>
              </Stack>
            </Stack>
          )}
          {!isFixed && (
            <Stack alignItems="center">
              <VSpacer size="2" />
              <Stack alignItems="center" alignSelf="center" direction="row">
                <Text category="body-large">
                  For every 1.000
                </Text>
                <HSpacer size="3" />
                <Select
                  disabled={disableUomSelector}
                  label={'Uom'}
                  onChangeValue={(uom) => (
                    onUpdate({ hideOnDashboard, rewardType, uom: uom as RewardsUom, value })
                  )}
                  testID={`${testID}-rewards-type`}
                  value={uom}
                  width={140}
                >
                  {UOMs.map(uom => (
                    <MenuItem key={uom} testID={`${testID}-rewards-type-${uom}`} value={uom}>
                      {formatRewardsUoM(1, uom)}
                    </MenuItem>
                  ))}
                </Select>
                <HSpacer size="3" />
                <Text category="body-large">
                  purchased,
                </Text>
              </Stack>
              <VSpacer size="6" />
              <Stack alignItems="center" alignSelf="center" direction="row">
                <Text category="body-large">
                  Customer will receive
                </Text>
                <HSpacer size="3" />
                <NumericInput
                  decimals={4}
                  minValue={0}
                  onChangeNumber={(value) => onUpdate({ hideOnDashboard, rewardType, uom, value })}
                  placeholder="$0.0000"
                  prefix='$'
                  testID={`${testID}-proportional-rewards-value`}
                  value={value}
                  width="112px"
                />
                <HSpacer size="3" />
                <Text category="body-large">
                  in points.
                </Text>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
      {!!onClickHideOnDashboard && (
        <>
          <VSpacer size="7" />
          <Checkbox
            checked={hideOnDashboard}
            onChangeChecked={onClickHideOnDashboard}
            testID="hide-on-customer-dashboard-checkbox"
          >
            <Text category="body-large">Hide on Customer Rewards Dashboard</Text>
          </Checkbox>
        </>
      )}
    </Stack>
  );
};
