import { GlobalSnackbarProvider } from '@/providers/GlobalSnackbarProvider';
import { ShoppingCartProvider } from '@/providers/ShoppingCartProvider';
import { OtherEventType, useLogEvent } from '@/utilities/Analytics';
import { getSubdomain, getTheme } from '@/utilities/ThemeUtilities';
import { LoggedInView } from '@/views/LoggedInView';
import { LoginView } from '@/views/LoginView';
import { StorefrontLoginView } from '@/views/StorefrontLoginView';
import { UnderConstructionView } from '@/views/UnderConstructionView';
import { CircularProgress, Container, CssBaseline, Stack } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { userIsFarmer, userIsInternal, userIsRetailer } from '@shared/utilities';
import React, { ReactNode, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { HashRouter, matchPath, PathMatch, useLocation, useNavigate } from 'react-router-dom';
import { VSpacer } from './components/DesignSystem';
import { AppConfig } from './constants/AppConfig';
import { DashboardRoutes, PublicRoutes, Routes } from './constants/Routes';
import {
  AuthenticationProvider,
  SessionState,
  useAuthentication,
} from './contexts/dataSync/AuthenticationContext';
import { InternalView } from './views/InternalView';
import { PublicView } from './views/PublicView';
import { RetailerView } from './views/RetailerView';
import { DashboardView } from './views/DashboardView';
import { HierarchyOfRetailersApi } from "@/utilities/api/HierarchyOfRetailersApi";
import { QueryKeys } from "@/constants/QueryKeys";

const MainView = () => {
  const location = useLocation();

  const { sessionState, user } = useAuthentication();
  const logEvent = useLogEvent();
  const navigate = useNavigate();
  const subdomain = getSubdomain();

  useEffect(() => {
    const routesToNotRedirect = [
      Routes.MY_PROFILE,
      ...DashboardRoutes,
    ] as string[];
    const isRedirectRoute = !routesToNotRedirect.includes(location.pathname);
    if (isRedirectRoute && user && !subdomain) {
      if (userIsRetailer(user)
        && (!user.otpVerified || !user.email || !user.businessName)
      ) {
        navigate(Routes.MY_PROFILE);
        return;
      } else if (userIsFarmer(user)
        && (!user.otpVerified || !user.firstName || !user.lastName || !user.state || !user.countyId)
      ) {
        navigate(Routes.MY_PROFILE);
        return;
      }
    }
    logEvent(OtherEventType.PageView, { page_location: location.pathname });
  }, [location, user]);

  useEffect(() => (
    window.scrollTo(0, 0)
  ), [location.pathname]);

  for (const route of DashboardRoutes) {
    const match = matchPath(
      route,
      location.pathname,
    );
    if (match) {
      return (<DashboardView />);
    }
  }

  for (const route of PublicRoutes) {
    const match = matchPath(
      route,
      location.pathname,
    );
    if (match) {
      return (<PublicView />);
    }
  }

  if (sessionState === SessionState.LOGGED_OUT) {
    if (subdomain) {
      return <StorefrontLoginView />;
    } else {
      return (AppConfig.env.prod || AppConfig.env.staging)
        ? <UnderConstructionView />
        : <LoginView />;
    }
  } else if (SessionState.LOGGED_IN && user) {
    // check UserType directly on user object and not the internal and isRetailer flag
    // since those states might not have been updated yet
    if (userIsInternal(user)) {
      return <InternalView />;
    } else if (userIsRetailer(user)) {
      return AppConfig.env.test ? <RetailerView /> : <UnderConstructionView />;
    }
    return (AppConfig.env.test || subdomain) ? <LoggedInView /> : <UnderConstructionView />;
  } else {
    return (
      <Container>
        <Stack alignItems="center">
          <VSpacer size="14" />
          <CircularProgress />
        </Stack>
      </Container>
    );
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const GrowersHelmet = () => {
  return <Helmet>
    <title>GROWERS</title>
    <style>{'body { background-color: #181818; }'}</style>
    <meta content="GROWERS" name="description"/>
    <link
      href="apple-icon-57x57.png"
      rel="apple-touch-icon"
      sizes="57x57"
    />
    <link
      href="apple-icon-60x60.png"
      rel="apple-touch-icon"
      sizes="60x60"
    />
    <link
      href="apple-icon-72x72.png"
      rel="apple-touch-icon"
      sizes="72x72"
    />
    <link
      href="apple-icon-76x76.png"
      rel="apple-touch-icon"
      sizes="76x76"
    />
    <link
      href="apple-icon-114x114.png"
      rel="apple-touch-icon"
      sizes="114x114"
    />
    <link
      href="apple-icon-120x120.png"
      rel="apple-touch-icon"
      sizes="120x120"
    />
    <link
      href="apple-icon-144x144.png"
      rel="apple-touch-icon"
      sizes="144x144"
    />
    <link
      href="apple-icon-152x152.png"
      rel="apple-touch-icon"
      sizes="152x152"
    />
    <link
      href="apple-icon-180x180.png"
      rel="apple-touch-icon"
      sizes="180x180"
    />
    <link
      href="android-icon-192x192.png"
      rel="icon"
      sizes="192x192"
      type="image/png"
    />
    <link
      href="growers-favicon.ico"
      rel="icon"
      sizes="32x32"
      type="image/png"
    />
    <link
      href="growers-favicon-96x96.png"
      rel="icon"
      sizes="96x96"
      type="image/x-icon"
    />
    <link
      href="growers-favicon.ico"
      rel="icon"
      sizes="16x16"
      type="image/x-icon"
    />
  </Helmet>;
};

const LoyaltyProgramHelmet = () => {
  const subdomain = window.location.hostname.split('.')[0];

  const { data: metadata } = useQuery(
    [QueryKeys.GET_LOYALTY_PROGRAM_METADATA, subdomain],
    () => HierarchyOfRetailersApi.getLoyaltyProgramMetadata(subdomain),
    { staleTime: 5 * 60 * 1000 },
  );
  const location = useLocation();

  const isCustomerLoyaltyDashboard = DashboardRoutes.reduce<PathMatch<'*' | 'accessToken'> | null>(
    (match, pattern) => (match ? match : matchPath(pattern, location.pathname)),
    null,
  );

  if (!metadata || !metadata.appIcon || !metadata.appName || !isCustomerLoyaltyDashboard) {
    return (
      <Helmet>
        {!!metadata?.appName && (
          <>
            <title>{metadata.appName}</title>
            <meta content={metadata.appName} name="description" />
          </>
        )}
        <style>{'body { background-color: #181818; }'}</style>
        <link
          href={`${AppConfig.staticImageHost}/icons/${subdomain}/favicon.png`}
          rel="icon"
          type="image/x-icon"
        />
      </Helmet>
    );
  }

  const { appIcon, appName } = metadata;
  const manifest = {
    name: appName,
    icons: [
      {
        sizes: 'any',
        src: `${AppConfig.staticImageHost}/icons/${subdomain}/192x192-${appIcon}`,
        type: 'image/png',
        purpose: 'any maskable',
      },
    ],
  };
  const stringManifest = JSON.stringify(manifest);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestUrl = URL.createObjectURL(blob);

  return <Helmet>
    <title>{appName}</title>
    <style>{'body { background-color: #181818; }'}</style>
    <meta content={appName} name="description" />
    <link
      href={`${AppConfig.staticImageHost}/icons/${subdomain}/${appIcon}`}
      rel="icon"
      type="image/png"
    />
    <link
      href={`${AppConfig.staticImageHost}/icons/${subdomain}/32x32-${appIcon}`}
      rel="icon"
      sizes="32x32"
      type="image/png"
    />
    <link
      href={`${AppConfig.staticImageHost}/icons/${subdomain}/180x180-${appIcon}`}
      rel="apple-touch-icon"
      sizes="180x180"
      type="image/png"
    />
    <link
      href={`${AppConfig.staticImageHost}/icons/${subdomain}/192x192-${appIcon}`}
      rel="icon"
      sizes="192x192"
      type="image/png"
    />
    <link
      href={`${AppConfig.staticImageHost}/icons/${subdomain}/512x512-${appIcon}`}
      rel="icon"
      sizes="512x512"
      type="image/png"
    />
    <link
      href={manifestUrl}
      rel="manifest"
    />
    <link
      href={`${AppConfig.staticImageHost}/icons/${subdomain}/favicon.png`}
      rel="icon"
      type="image/x-icon"
    />
  </Helmet>;
};

const AppHelmet = () => {
  const isLoyaltyProgramDomain = window.location.hostname.includes("loyalty.ag");
  return isLoyaltyProgramDomain ? <LoyaltyProgramHelmet /> : <GrowersHelmet />;
};

const DynamicThemeProvider = ({ children } :{
  children: ReactNode,
}) => {
  const [theme, setTheme] = useState(getTheme());
  const location = useLocation();

  useEffect(() => {
    const newTheme = getTheme();
    if (theme.palette.mode !== newTheme.palette.mode) {
      setTheme(newTheme);
    }
  }, [location.pathname]);

  return (
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  );
};

const App = () => {
  return (
    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <DynamicThemeProvider>
          <CssBaseline />
          <GlobalSnackbarProvider>
            <ShoppingCartProvider>
              <AuthenticationProvider>
                <AppHelmet />
                <MainView />
              </AuthenticationProvider>
            </ShoppingCartProvider>
          </GlobalSnackbarProvider>
        </DynamicThemeProvider>
      </QueryClientProvider>
    </HashRouter>
  );
};

export default App;
