import { Grid, Stack } from '@mui/material';
import { HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import React, { useState } from 'react';
import { ApiRetailer } from '@api/interfaces';
import {
  DeliverableTemplateModal,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/BrandAssets/DeliverableTemplateModal';
import { RetailerEndpoint } from '@api/endpoints';
import {
  BrandColorModal,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/BrandAssets/BrandColorModal';
import ConfirmModal from '@/components/shared/ConfirmModal';
import {
  PropertyCardGridItem,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/BrandAssets/PropertyCardGridItem';
import { DeliverableTemplateType } from '@shared/enums';
import { FriendlyDeliverableTemplateType } from '@/constants/FriendlyDeliverableTemplateType';
import { ImageModal } from '@/pages/Admin/HierarchyOfRetailers/Retailer/BrandAssets/ImageModal';
import { WebsiteModal } from '@/pages/Admin/HierarchyOfRetailers/Retailer/BrandAssets/WebsiteModal';
import {
  PropertyModal,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/BrandAssets/PropertyModal';
import { formatPhoneNumber, getOriginalFileName } from '@shared/utilities';
import { Error } from '@mui/icons-material';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { SharedConfig } from '@shared/SharedConfig';
import { UtilityApi } from '@/utilities/api/UtilityApi';
import { SmsApi } from "@/utilities/api/SmsApi";
import { AppConfig } from "@/constants/AppConfig";

interface BrandAssetInputsProps {
  onChange: (updates: RetailerEndpoint.Update.Request) => void,
  retailer: ApiRetailer,
}

export const BrandAssetInputs = ({
  onChange,
  retailer,
}: BrandAssetInputsProps) => {
  const { openSnackbar } = useSnackbar();
  const [showLogoModal, setShowLogoModal] = useState(false);
  const [showBrandColorModal, setShowBrandColorModal] = useState(false);
  const [showDeliverableTemplateModal, setShowDeliverableTemplateModal] = useState(false);
  const [showWebsiteModal, setShowWebsiteModal] = useState(false);
  const [showAppNameModal, setShowAppNameModal] = useState(false);
  const [showAppIconModal, setShowAppIconModal] = useState(false);
  const [showSmsPhoneModal, setShowSmsPhoneModal] = useState(false);
  const [showShortUrlModal, setShowShortUrlModal] = useState(false);
  const [showRemoveLogoDialog, setShowRemoveLogoDialog] = useState(false);
  const [showRemoveBrandColorDialog, setShowRemoveBrandColorDialog] = useState(false);
  const [showRemoveTemplateDialog, setShowRemoveTemplateDialog] = useState(false);
  const [showRemoveWebsiteDialog, setShowRemoveWebsiteDialog] = useState(false);
  const [showRemoveAppNameDialog, setShowRemoveAppNameDialog] = useState(false);
  const [showRemoveAppIconDialog, setShowRemoveAppIconDialog] = useState(false);
  const [showRemoveSmsPhoneDialog, setShowRemoveSmsPhoneDialog] = useState(false);
  const [showRemoveShortUrlDialog, setShowRemoveShortUrlDialog] = useState(false);

  return (
    <>
      <Stack>
        <Stack alignItems="center" direction="row">
          <Text category="body-xlarge">
            Brand Assets
          </Text>
          <HSpacer size="5" />
          <Error color="info" sx={{ height: '18px', width: '18px' }}/>
          <HSpacer size="3" />
          <Text category="body-large" color="info">
            All fields required
          </Text>
        </Stack>
        <VSpacer size="5"/>
        <Grid container spacing={2}>
          <PropertyCardGridItem
            onAddClicked={() => setShowLogoModal(true)}
            onEditClicked={() => setShowLogoModal(true)}
            onRemoveClicked={() => setShowRemoveLogoDialog(true)}
            statusText={retailer.image && getOriginalFileName(retailer.image)}
            title="Retailer Logo"
          />
          <PropertyCardGridItem
            color={retailer.brandColor}
            onAddClicked={() => setShowBrandColorModal(true)}
            onEditClicked={() => setShowBrandColorModal(true)}
            onRemoveClicked={() => setShowRemoveBrandColorDialog(true)}
            statusText={retailer.brandColor}
            title="Brand Color"
          />
          <PropertyCardGridItem
            color={retailer.templateType && (
              retailer.templateType === DeliverableTemplateType.WhiteText ? (
                '#FFFFFF'
              ) : (
                '#000000'
              )
            )}
            onAddClicked={() => setShowDeliverableTemplateModal(true)}
            onEditClicked={() => setShowDeliverableTemplateModal(true)}
            onRemoveClicked={() => setShowRemoveTemplateDialog(true)}
            statusText={retailer.templateType && (
              FriendlyDeliverableTemplateType[retailer.templateType]
            )}
            title="Web Deliverable"
          />
          <PropertyCardGridItem
            onAddClicked={() => setShowWebsiteModal(true)}
            onEditClicked={() => setShowWebsiteModal(true)}
            onRemoveClicked={() => setShowRemoveWebsiteDialog(true)}
            statusText={retailer.websiteUrl}
            title="Retailer Website"
          />
          <PropertyCardGridItem
            onAddClicked={() => setShowAppNameModal(true)}
            onEditClicked={() => setShowAppNameModal(true)}
            onRemoveClicked={() => setShowRemoveAppNameDialog(true)}
            statusText={retailer.appName}
            title="App name"
          />
          <PropertyCardGridItem
            onAddClicked={() => {
              if (!retailer.shortUrlName) {
                openSnackbar('Please add a "Short URL" first');
                return;
              }
              setShowAppIconModal(true);
            }}
            onEditClicked={() => setShowAppIconModal(true)}
            onRemoveClicked={() => setShowRemoveAppIconDialog(true)}
            statusText={retailer.appIcon}
            title="App icon"
          />
          <PropertyCardGridItem
            onAddClicked={() => setShowSmsPhoneModal(true)}
            onEditClicked={() => setShowSmsPhoneModal(true)}
            onRemoveClicked={() => setShowRemoveSmsPhoneDialog(true)}
            statusText={retailer.messagingTelephone && (
              formatPhoneNumber(retailer.messagingTelephone, 'paren')
            )}
            title="SMS phone number"
          />
          <PropertyCardGridItem
            onAddClicked={() => setShowShortUrlModal(true)}
            onEditClicked={() => {
              if (retailer.appIcon) {
                openSnackbar('Please remove the existing "App icon" first');
                return;
              }
              setShowShortUrlModal(true);
            }}
            onRemoveClicked={() => {
              if (retailer.appIcon) {
                openSnackbar('Please remove the existing "App icon" first');
                return;
              }
              setShowRemoveShortUrlDialog(true);
            }}
            statusText={retailer.shortUrlName}
            title="Short URL"
          />
        </Grid>
      </Stack>
      {showLogoModal && (
        <ImageModal
          imageFilename={retailer.image}
          onClose={() => setShowLogoModal(false)}
          onSave={(image) => onChange({ image })}
          title="Upload Logo"
        />
      )}
      {showBrandColorModal && (
        <BrandColorModal
          brandColor={retailer.brandColor}
          onClose={() => setShowBrandColorModal(false)}
          onSave={(brandColor) => onChange({ brandColor })}
        />
      )}
      {showDeliverableTemplateModal && (
        <DeliverableTemplateModal
          onClose={() => setShowDeliverableTemplateModal(false)}
          onSaveBrandColor={(brandColor) => onChange({ brandColor })}
          onSaveTemplateType={(templateType) => onChange({ templateType })}
          retailer={retailer}
        />
      )}
      {showWebsiteModal && (
        <WebsiteModal
          onClose={() => setShowWebsiteModal(false)}
          onSave={(websiteUrl) => onChange({ websiteUrl })}
          url={retailer.websiteUrl}
        />
      )}
      {showAppNameModal && (
        <PropertyModal
          existingValue={retailer.appName}
          onClose={() => setShowAppNameModal(false)}
          onSave={(appName) => onChange({ appName })}
          propertyName="App name"
          validate={(value) => value.length <= 25}
        />
      )}
      {showAppIconModal && (
        <ImageModal
          acceptedFileTypes="PNG"
          acceptedMimeTypes="image/png"
          imageFilename={retailer.appIcon && `icons/${retailer.shortUrlName}/${retailer.appIcon}`}
          onClose={() => setShowAppIconModal(false)}
          onSave={(appIcon) => onChange({ appIcon })}
          title="App icon"
        />
      )}
      {showSmsPhoneModal && (
        <PropertyModal
          existingValue={retailer.messagingTelephone?.slice(-10)}
          isPhoneNumber
          onClose={() => setShowSmsPhoneModal(false)}
          onSave={(messagingTelephone) => onChange({ messagingTelephone: `1${messagingTelephone}` })}
          propertyName="SMS phone number"
          validate={(value) => value.length === 10 && /^\d+$/.test(value)}
          validateOnSave={async (value) => {
            const isPhoneNumberOwned = await SmsApi.isPhoneNumberOwned(value);
            if (!isPhoneNumberOwned) {
              openSnackbar('SMS phone number must be owned in Twilio before it can be saved');
            }
            return isPhoneNumberOwned;
          }}
        />
      )}
      {showShortUrlModal && (
        <PropertyModal
          existingValue={retailer.shortUrlName}
          onClose={() => setShowShortUrlModal(false)}
          onSave={(shortUrlName) => onChange({ shortUrlName })}
          propertyName="Short URL name"
          validate={(value) => /^[a-zA-Z0-9-]+$/.test(value)}
          validateOnSave={async (shortUrlName) => {
            if (AppConfig.env.local) {
              return true;
            }

            let subdomain;
            if (AppConfig.env.devel) {
              subdomain = `${shortUrlName}.devel`;
            } else if (AppConfig.env.staging) {
              subdomain = `${shortUrlName}.staging`;
            } else {
              subdomain = `${shortUrlName}`;
            }

            const urlToTest = `https://${subdomain}.${SharedConfig.shortUrlDomain}/`;
            const isReachable = await UtilityApi.getIsUrlReachable(urlToTest);
            if (!isReachable) {
              openSnackbar('Short url subdomain must be set-up before it can be saved');
            }
            return isReachable;
          }}
        />
      )}
      {showRemoveLogoDialog && (
        <ConfirmModal
          confirmButtonText="Yes"
          message={
            <Text category="headline-small">
              Are you sure you want to remove the logo?
            </Text>
          }
          onCancel={() => setShowRemoveLogoDialog(false)}
          onConfirm={() => {
            onChange({ image: null });
            setShowRemoveLogoDialog(false);
          }}
          open
          testID="remove-logo-dialog"
        />
      )}
      {showRemoveBrandColorDialog && (
        <ConfirmModal
          confirmButtonText="Yes"
          message={
            <Text category="headline-small">
              Are you sure you want to remove the brand color?
            </Text>
          }
          onCancel={() => setShowRemoveBrandColorDialog(false)}
          onConfirm={() => {
            onChange({ brandColor: null, templateType: null });
            setShowRemoveBrandColorDialog(false);
          }}
          open
          testID="remove-brand-color-dialog"
        />
      )}
      {showRemoveTemplateDialog && (
        <ConfirmModal
          confirmButtonText="Yes"
          message={
            <Text category="headline-small">
              Are you sure you want to remove the deliverable template?
            </Text>
          }
          onCancel={() => setShowRemoveTemplateDialog(false)}
          onConfirm={() => {
            onChange({ templateType: null });
            setShowRemoveTemplateDialog(false);
          }}
          open
          testID="remove-template-dialog"
        />
      )}
      {showRemoveWebsiteDialog && (
        <ConfirmModal
          confirmButtonText="Yes, delete"
          message={
            <Text category="headline-small">
              Are you sure you want to remove the retailer website?
            </Text>
          }
          onCancel={() => setShowRemoveWebsiteDialog(false)}
          onConfirm={() => {
            onChange({ websiteUrl: null });
            setShowRemoveWebsiteDialog(false);
          }}
          open
          testID="remove-website-dialog"
          title="Are you sure?"
        />
      )}
      {showRemoveAppNameDialog && (
        <ConfirmModal
          confirmButtonText="Yes"
          message={
            <Text category="headline-small">
              Are you sure you want to remove the app name?
            </Text>
          }
          onCancel={() => setShowRemoveAppNameDialog(false)}
          onConfirm={() => {
            onChange({ appName: null });
            setShowRemoveAppNameDialog(false);
          }}
          open
          testID="remove-app-name-dialog"
        />
      )}
      {showRemoveAppIconDialog && (
        <ConfirmModal
          confirmButtonText="Yes"
          message={
            <Text category="headline-small">
              Are you sure you want to remove the app icon?
            </Text>
          }
          onCancel={() => setShowRemoveAppIconDialog(false)}
          onConfirm={() => {
            onChange({ appIcon: null });
            setShowRemoveAppIconDialog(false);
          }}
          open
          testID="remove-app-icon-dialog"
        />
      )}
      {showRemoveSmsPhoneDialog && (
        <ConfirmModal
          confirmButtonText="Yes"
          message={
            <Text category="headline-small">
              Are you sure you want to remove the SMS phone number?
            </Text>
          }
          onCancel={() => setShowRemoveSmsPhoneDialog(false)}
          onConfirm={() => {
            onChange({ messagingTelephone: null });
            setShowRemoveSmsPhoneDialog(false);
          }}
          open
          testID="remove-sms-phone-dialog"
        />
      )}
      {showRemoveShortUrlDialog && (
        <ConfirmModal
          confirmButtonText="Yes"
          message={
            <Text category="headline-small">
              Are you sure you want to remove the short URL name?
            </Text>
          }
          onCancel={() => setShowRemoveShortUrlDialog(false)}
          onConfirm={() => {
            onChange({ shortUrlName: null });
            setShowRemoveShortUrlDialog(false);
          }}
          open
          testID="remove-short-url-dialog"
        />
      )}
    </>
  );
};
