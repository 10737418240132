import { Card, Text, TextBadge, VSpacer } from '@/components/DesignSystem';
import { formatAmount, getRemainingPctToHurdle } from '@/pages/CustomerDashboard/helpers';
import { ResolvedRewardsProgram, ResolvedTier } from '@/pages/CustomerDashboard/interfaces';
import { ProgramExclusionsDisclaimer } from '@/pages/CustomerDashboard/ProgramExclusionsDisclaimer';
import { ApiCategory } from '@api/interfaces';
import { ApiFarmerRewards } from '@api/interfaces/ApiFarmerRewards';
import { alpha, LinearProgress, Stack, useTheme } from '@mui/material';
import { RewardsUom } from '@shared/enums';
import { buildRequirementTextForTier } from '@shared/utilities';
import React, { useMemo } from 'react';

interface TierProgressProps {
  brandColor: string,
  categories: ApiCategory[],
  isReachedFinalTier: boolean,
  nextTier: ResolvedTier,
  program: ResolvedRewardsProgram,
  rewards: ApiFarmerRewards,
}

export const TierProgress = ({
  brandColor,
  categories,
  isReachedFinalTier,
  nextTier,
  program,
  rewards,
}: TierProgressProps) => {
  const theme = useTheme();

  const howToReachNextTierText = useMemo(() => {
    return buildRequirementTextForTier(nextTier, isReachedFinalTier);
  }, [isReachedFinalTier, nextTier]);

  const hasExclusions = !!program.categoryIdsExcludedFromTierSpend.length
    || !!program.categoryIdsExcludedFromSegmentSpend.length;

  const filteredSegments = useMemo(() => {
    return nextTier.segments
      .filter(segment => !program.categoryIdsExcludedFromSegmentSpend.includes(segment.categoryId))
      .sort((a, b) => {
        const aRemaining = getRemainingPctToHurdle(a, rewards.totalPerSegment, nextTier);
        const bRemaining = getRemainingPctToHurdle(b, rewards.totalPerSegment, nextTier);
        if (aRemaining <= 0 && bRemaining <= 0) {
          const aCategory = categories.find((c) => c.id === a.categoryId)!;
          const bCategory = categories.find((c) => c.id === b.categoryId)!;
          return aCategory.name.localeCompare(bCategory.name);
        } else if (aRemaining <= 0 || bRemaining <= 0) {
          return aRemaining <= 0 ? 1 : -1;
        } else {
          return aRemaining - bRemaining;
        }
      });
  }, [categories, nextTier, rewards]);

  return (
    <Stack px="16px">
      <VSpacer size="5" />
      <Text category="body-medium">
        {howToReachNextTierText}
      </Text>
      <VSpacer size="5" />
      {filteredSegments.map((segment) => {
        const { qualifyingTotalSpend, total } = rewards.totalPerSegment[segment.categoryId];
        const category = categories.find((c) => c.id === segment.categoryId)!;
        const segmentToHurdle = nextTier.segments.find(({ categoryId }) => (
          categoryId === segment.categoryId
        ))!;
        const remainingToHurdle = segmentToHurdle.minimumHurdle - total;
        const isNotYetStarted = total === 0;
        const isHurdleMet = remainingToHurdle <= 0;
        const { uom } = segmentToHurdle;
        const isDollar = uom === RewardsUom.Dollars;
        const remainingText = `${formatAmount(remainingToHurdle, isDollar, uom)} remaining`;
        const goalText = `Goal: ${formatAmount(segmentToHurdle.minimumHurdle, isDollar, uom)}`;
        const barProgress = Math.min(total / segmentToHurdle.minimumHurdle * 100, 100);
        return (
          <>
            <Card
              cardContentStyle={{ p: '16px' }}
              elevation={0}
              key={segment.id}
              sx={{ backgroundColor: isHurdleMet
                ? alpha(theme.palette.success.main, .12) : "#f5f5f5" }}
              testID={`${segment.id}-card`}
            >
              <Stack alignItems="center" direction="row" justifyContent="space-between">
                <Text category="title-medium">
                  {category.name}
                </Text>
                {isHurdleMet && (
                  <TextBadge backgroundColor={theme.palette.success.main} text="COMPLETE" />
                )}
              </Stack>
              <Text category="body-medium">
                Current spend: {formatAmount(qualifyingTotalSpend ?? 0)}
              </Text>
              {(isNotYetStarted || isHurdleMet) ? (
                <VSpacer size="3" />
              ) : (
                <>
                  <Text category="title-small" textAlign="right">
                    {remainingText}
                  </Text>
                  <VSpacer size="2" />
                </>
              )}
              <LinearProgress
                sx={{
                  bgcolor: theme.palette.grey[400],
                  borderRadius: '8px',
                  '.MuiLinearProgress-bar1Determinate': {
                    bgcolor: isHurdleMet ? '#237804' : theme.palette.grey[900],
                  },
                  height: '6px',
                }}
                value={barProgress}
                variant="determinate"
              />
              <VSpacer size="2"/>
              <Stack alignItems="end">
                <Text category="body-medium">
                  {goalText}
                </Text>
              </Stack>
            </Card>
            <VSpacer size="4" />
          </>
        );
      })}
      {hasExclusions && (
        <>
          <VSpacer size="3" />
          <ProgramExclusionsDisclaimer
            brandColor={brandColor}
            categories={categories}
            segmentSpendCategoryExclusionIds={program.categoryIdsExcludedFromSegmentSpend}
            tierSpendCategoryExclusionIds={program.categoryIdsExcludedFromTierSpend}
          />
          <VSpacer size="4" />
        </>
      )}
      <VSpacer size="9" />
    </Stack>
  );
};
