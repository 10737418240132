import { Button, HSpacer, MenuItem, Text } from "@/components/DesignSystem";
import React, { useCallback, useEffect, useState } from "react";
import { ApiRetailer } from "@api/interfaces";
import { ListItemIcon, ListItemText, Menu, Stack } from "@mui/material";
import Store from "@mui/icons-material/Store";
import { AppConfig } from "@/constants/AppConfig";
import { ReactComponent as GrowersLogo } from '@/assets/icons/growers-icon.svg';
import { ReactComponent as ManufacturerLogo } from '@/assets/icons/manufacturer-icon.svg';
import { ReactComponent as ProductLogo } from '@/assets/logos/black/custom_product_black.svg';
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { HierarchyOfRetailersApi } from "@/utilities/api/HierarchyOfRetailersApi";
import { Check } from "@mui/icons-material";
import { useGetRetailerList } from "@/hooks/useProductQuery";
import { isBoolean } from "lodash";
import { Avatar } from "@/components/DesignSystem/Avatar/Avatar";

const ERPRetailerSelector = ({
  defaultMenuItem,
  isErpEnabled,
  hasErpProducts,
  header,
  selectedId,
  onSelect,
} : {
  defaultMenuItem?: {
    label: string,
    logo: 'growers' | 'manufacturer' | 'product' | 'store' | 'none'
  },
  isErpEnabled?: boolean,
  hasErpProducts?: boolean,
  header: string,
  selectedId?: string | null,
  onSelect: (retailer?: ApiRetailer) => void,
}) => {
  const [selectedRetailer, setSelectedRetailer] = useState<null | ApiRetailer>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { retailerList } = useGetRetailerList({
    ...(isBoolean(isErpEnabled) && { isErpEnabled }),
    ...(isBoolean(hasErpProducts) && { hasErpProducts }),
  });

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRetailerSelected = useCallback(async (selectedRetailerId?: string) => {
    setAnchorEl(null);

    if (!selectedRetailerId) {
      setSelectedRetailer(null);
      onSelect();
      return;
    }

    const { data } = await HierarchyOfRetailersApi.listRetailers(
      { id: [selectedRetailerId] },
    );
    setSelectedRetailer(data[0]);
    onSelect(data[0]);
  }, []);

  useEffect(() => {
    if (selectedId) {
      void handleRetailerSelected(selectedId);
    }
  }, [handleRetailerSelected, selectedId]);

  const logoSrc = selectedRetailer?.image && `${AppConfig.staticImageHost}/${selectedRetailer.image}`;

  const getDisplayIcon = () => {
    if (!defaultMenuItem?.logo || (selectedRetailer && !logoSrc)) {
      return <Store sx={{ height: 16, width: 16 }} />;
    }

    if (defaultMenuItem.logo === 'growers') {
      return <GrowersLogo height={16} width={16}/>;
    } else if (defaultMenuItem.logo === 'manufacturer') {
      return <ManufacturerLogo height={16} width={16}/>;
    } else if (defaultMenuItem.logo === 'product') {
      return <ProductLogo height={16} width={16}/>;
    } else if (defaultMenuItem.logo === 'store') {
      return <Store sx={{ height: 16, width: 16 }} />;
    }
    return null;
  };

  return (
    <Stack alignItems="center" direction="row">
      <Text category="headline-medium">{header}</Text>
      <HSpacer size='6' />
      <Button
        onClick={(e) => handleClick(e)}
        square={true}
        testID="retailer-selector-menu"
      >
        <Stack alignItems="center" direction="row">
          <Text category="label-medium">Select a list</Text>
          <HSpacer size="3"/>
          <ArrowDropDown sx={{ height: '16px', width: '16px' }} />
        </Stack>
      </Button>
      <HSpacer size='5' />
      <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
        {defaultMenuItem && (
          <MenuItem
            key='default-menu-item'
            onClick={() => handleRetailerSelected()}
            selected={!selectedRetailer?.id}
            testID="default-menu-item"
            value='default-menu-item'
          >
            <ListItemText>{defaultMenuItem.label}</ListItemText>
            {!selectedRetailer?.id && (
              <ListItemIcon>
                <Check color='primary' />
              </ListItemIcon>
            )}
          </MenuItem>
        )}
        {retailerList?.map((retailer) => {
          return (
            <MenuItem
              key={retailer.id}
              onClick={() => handleRetailerSelected(retailer.id)}
              selected={selectedRetailer?.id === retailer.id}
              testID={`menu-item-${retailer.id}`}
              value={retailer.id}
            >
              <ListItemText>{retailer.name}</ListItemText>
              {selectedRetailer?.id === retailer.id && (
                <ListItemIcon>
                  <Check color='primary' />
                </ListItemIcon>
              )}
            </MenuItem>
          );
        })}
      </Menu>
      {(!!selectedRetailer || defaultMenuItem?.logo !== 'none') && (
        <Avatar
          alt={`${selectedRetailer?.name ?? 'retailer'} logo`}
          height={24}
          src={logoSrc ?? undefined}
          width={24}
        >
          {logoSrc ? null : getDisplayIcon()}
        </Avatar>
      )}
      <HSpacer size="4" />
      <Text category="title-large">
        {selectedRetailer ? selectedRetailer.name : defaultMenuItem?.label ?? ''}
      </Text>
    </Stack>
  );
};

export default ERPRetailerSelector;
