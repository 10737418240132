import { TextLink } from '@/components/DesignSystem';
import { Box, Button as MuiButton, Stack } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { useRef } from 'react';

interface ImageInputProps {
  acceptedMimeTypes?: string,
  imageSrc?: string | null,
  onChangeImage: (file: File) => void,
  onRemove: () => void,
}

export const ImageInput = ({
  acceptedMimeTypes = 'image/png, image/jpeg, image/svg+xml',
  imageSrc,
  onChangeImage,
  onRemove,
}: ImageInputProps) => {
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const UploadButton = (
    <MuiButton
      data-testid='location-upload-logo'
      startIcon={<UploadIcon />}
      sx={{
        color: 'white',
        borderColor: 'white',
        margin: '25px auto',
      }}
      variant='outlined'
    >
      Upload icon
      <input
        accept={acceptedMimeTypes}
        id='raised-button-file'
        onChange={(e) => {
          const imageFile = e.target.files?.[0];
          if (imageFile) {
            onChangeImage(imageFile);
          }
        }}
        ref={fileUploadRef}
        style={{ display: 'none' }}
        type='file'
      />
    </MuiButton>
  );

  const ImageDisplay = (
    <Stack alignItems={'center'}>
      <img
        height={'60%'}
        src={imageSrc!}
        style={{ objectFit: 'cover', padding: '20px 0px' }}
        width={'60%'}
      />
      <Stack direction='row' justifyContent={'space-between'}>
        <TextLink
          category="body-medium"
          onClick={onRemove}
          testID="remove-textlink"
        >
          remove
        </TextLink>
      </Stack>
    </Stack>
  );

  return (
    <Box
      onClick={() => fileUploadRef.current?.click()}
      style={{
        alignItems: 'center',
        border: '0.7px dashed grey',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {imageSrc ? ImageDisplay : UploadButton}
    </Box>
  );
};