import { HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { ApiOrderTransaction } from '@api/interfaces/ApiOrderTransaction';
import { Remove, Star } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import { Stack } from '@mui/material';
import { TransactionType } from '@shared/enums';
import { formatCurrency } from '@shared/utilities';
import { getAccountName } from '@shared/utilities/UserUtilities';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';

interface TransactionHistoryListItemProps {
  transaction: ApiOrderTransaction,
}
export const TransactionHistoryListItem = ({
  transaction,
}: TransactionHistoryListItemProps) => {
  const [pointsEarned, setPointsEarned] = useState(0);
  const [pointsRedeemed, setPointsRedeemed] = useState(0);
  const [pointsRefunded, setPointsRefunded] = useState(0);
  const [pointsReturned, setPointsReturned] = useState(0);

  useEffect(() => {
    let earned = 0;
    let redeemed = 0;
    let refunded = 0;
    let returned = 0;

    transaction.userLoyaltyPointsHistories?.map((userLoyaltyPointsHistory) => {
      if (userLoyaltyPointsHistory.type === TransactionType.CREDIT) {
        earned += userLoyaltyPointsHistory.amount;
      } else if (userLoyaltyPointsHistory.type === TransactionType.CORRECTION) {
        earned -= userLoyaltyPointsHistory.amount;
      } else if (userLoyaltyPointsHistory.type === TransactionType.DEBIT) {
        redeemed += userLoyaltyPointsHistory.amount;
      } else if (userLoyaltyPointsHistory.type === TransactionType.REDEMPTION_REFUND) {
        refunded += userLoyaltyPointsHistory.amount;
      } else if (userLoyaltyPointsHistory.type === TransactionType.RETURN) {
        returned += userLoyaltyPointsHistory.amount;
      } else if (userLoyaltyPointsHistory.type === TransactionType.RETURN_CORRECTION) {
        returned -= userLoyaltyPointsHistory.amount;
      }
    });

    setPointsEarned(earned);
    setPointsRedeemed(redeemed);
    setPointsRefunded(refunded);
    setPointsReturned(returned);
  }, []);

  return (
    <Stack direction="row" justifyContent="space-between" py="16px">
      <Stack mr="8px">
        <Text category="title-medium">
          {DateTime.fromJSDate(
            transaction.orderDate, { zone: 'America/New_York' },
          ).toFormat('MMM dd, yyyy')}
        </Text>
        <VSpacer size="2" />
        <Text category="body-medium">{getAccountName(transaction.farmer!)}</Text>
        <VSpacer size="2" />
        <Text category="label-medium" sx={{ color: '#D8D8D8' }}>
          Order {transaction.externalId}
        </Text>
      </Stack>
      <Stack alignItems="flex-end">
        {transaction.totalCost !== 0 && (
          <Text category="title-medium">{formatCurrency(transaction.totalCost)}</Text>
        )}
        {pointsRedeemed > 0 && (
          <Stack alignItems="center" direction="row" paddingTop="2px">
            <Remove color="error" style={{ width: '12px' }} />
            <HSpacer size="2" />
            <Text category="body-medium" color="error">
              {pointsRedeemed.toFixed(2).replace(/[.,]00$/, "")}
            </Text>
            <HSpacer size="2" />
            <Star color="error" style={{ width: '12px' }} />
            <HSpacer size="2" />
            <Text category="body-medium" color="error">
              redeemed
            </Text>
          </Stack>
        )}
        {pointsRedeemed < 0 && (
          <Stack alignItems="center" direction="row" paddingTop="2px">
            <Add color="success" style={{ width: '12px' }} />
            <HSpacer size="2" />
            <Text category="body-medium" color="success">
              {Math.abs(pointsRedeemed).toFixed(2).replace(/[.,]00$/, "")}
            </Text>
            <HSpacer size="2" />
            <Star color="error" style={{ width: '12px' }} />
            <HSpacer size="2" />
            <Text category="body-medium" color="success">
              refunded
            </Text>
          </Stack>
        )}
        {pointsEarned > 0 && (
          <Stack alignItems="center" direction="row" paddingTop="2px">
            <Add color="success" style={{ width: '12px' }} />
            <HSpacer size="2" />
            <Text category="body-medium" color="success">
              {pointsEarned.toFixed(2).replace(/[.,]00$/, "")}
            </Text>
            <HSpacer size="2" />
            <Star color="success" style={{ width: '12px' }} />
            <HSpacer size="2" />
            <Text category="body-medium" color="success">
              earned
            </Text>
          </Stack>
        )}
        {pointsReturned > 0 && (
          <Stack alignItems="center" direction="row" paddingTop="2px">
            <Add style={{ width: '12px' }} />
            <HSpacer size="2" />
            <Text category="body-medium">
              {pointsReturned.toFixed(2).replace(/[.,]00$/, "")}
            </Text>
            <HSpacer size="2" />
            <Star style={{ width: '12px' }} />
            <HSpacer size="2" />
            <Text category="body-medium">
              returned
            </Text>
          </Stack>
        )}
        {pointsRefunded > 0 && (
          <Stack alignItems="center" direction="row" paddingTop="2px">
            <Add style={{ width: '12px' }} />
            <HSpacer size="2" />
            <Text category="body-medium">
              {pointsRefunded.toFixed(2).replace(/[.,]00$/, "")}
            </Text>
            <HSpacer size="2" />
            <Star style={{ width: '12px' }} />
            <HSpacer size="2" />
            <Text category="body-medium">
              refunded
            </Text>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
