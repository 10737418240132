import { UserEndpoint } from '@api/endpoints';
import { QueryUtil } from '../Query';
import { Client } from './Client';

export class UserApi {
  static create (
    request: UserEndpoint.Create.Request,
  ): Promise<UserEndpoint.Create.Response> {
    return Client(`admin/users`, { body: request, method: 'POST' });
  }

  static delete (id: string): Promise<void> {
    return Client(`users/${id}`, { method: 'DELETE' });
  }

  static get (id: string): Promise<UserEndpoint.Get.Response> {
    return Client(`users/${id}`);
  }

  static getAssociatedSellers (id: string): Promise<UserEndpoint.ListAssociatedSellers.Response> {
    return Client(`users/${id}/associated-sellers`);
  }

  static getLinkedAccounts (id: string): Promise<UserEndpoint.LinkedAccounts.Response> {
    return Client(`users/${id}/linked-accounts`);
  }

  static async getLocationsForUser (id: string): Promise<UserEndpoint.ListLocations.Response> {
    return Client(`users/${id}/locations`);
  }

  static getDevicesForUser (userId: string): Promise<UserEndpoint.ListDevices.Response> {
    return Client(`users/${userId}/devices`);
  }

  static async getPreferredRetailer (
    userId: string,
  ): Promise<UserEndpoint.GetPreferredRetailer.Response> {
    return Client(`users/${userId}/preferred-retailer`);
  }

  static list (
    filters?: UserEndpoint.List.Query,
    usePost?: boolean,
  ): Promise<UserEndpoint.List.Response> {
    if (usePost) {
      return Client('users/list', { body: filters, method: 'POST' });
    }
    if (filters && filters.retailerId === null) {
      filters.retailerId = 'null';
    }
    const query = QueryUtil.stringify(filters ?? {});
    return Client(`users?${query}`);
  }

  static async listPointsForProgramPeriod (
    userId: string,
    programId: string,
    accessToken?: string,
  ): Promise<UserEndpoint.ListPointsForProgramPeriod.Response> {
    const url = accessToken
      ? `users/${userId}/points/${programId}?accessToken=${accessToken}`
      : `users/${userId}/points/${programId}`;
    return Client(url);
  }

  static me (): Promise<UserEndpoint.Get.Response> {
    return Client(`users/me`);
  }

  static resendVerifyEmail (id: string): Promise<void> {
    return Client(`users/${id}/resend-verify-email`, {
      method: 'POST',
    });
  }

  static switchAccount (
    token: string,
    storefrontId: string | null,
  ): Promise<UserEndpoint.SwitchAccount.Response> {
    const body: UserEndpoint.SwitchAccount.Request = { storefrontId, token };
    return Client(`switch-account`, { body });
  }

  static update (
    userId: string,
    data: UserEndpoint.Update.Request,
  ): Promise<UserEndpoint.Update.Response> {
    return Client(`users/${userId}`, {
      method: 'PATCH',
      body: { id: userId, ...data },
    });
  }

  static verifyEmail (token: string): Promise<void> {
    const query = QueryUtil.stringify({ token });
    return Client(`verify-email?${query}`, { method: 'POST' });
  }

  static getVerifiedStatus (
    id: string,
  ): Promise<UserEndpoint.VerifiedStatus.Response> {
    return Client(`users/${id}/verified`);
  }

  static getFarmerRewards (farmerId: string): Promise<UserEndpoint.ListFarmerRewards.Response> {
    return Client(`users/${farmerId}/farmer-rewards`);
  }

  static getLoyaltyBalances (farmerId?: string): Promise<UserEndpoint.LoyaltyBalance.Response> {
    const query = QueryUtil.stringify({ userId: farmerId });
    return Client(`users/rewards?${query}`);
  }

  static addFarmerRewardsById (
    farmerId: string,
    body: UserEndpoint.LoyaltyBalance.AdjustLoyaltyPoints,
  ) {
    return Client(`users/${farmerId}/rewards/credit-points`, {
      method: 'POST',
      body,
    });
  }
}
