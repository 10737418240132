export enum Routes {
  ACCEPT_OFFER_SUCCESS = '/acceptOfferSuccess',
  ADMIN_HOME_PAGE = '/admin/homepage',
  ADMIN_HIERARCHY_OF_RETAILERS = '/admin/hierarchy-of-retailers',
  ADMIN_LIST_ADMINS = '/admin/admins',
  ADMIN_LIST_CUSTOMERS = '/admin/customers',
  ADMIN_LIST_MANUFACTURERS = '/admin/manufacturers',
  ADMIN_LIST_NOTIFICATIONS = '/admin/notifications',
  ADMIN_LIST_ORDERS = '/admin/orders',
  ADMIN_LIST_PROMOTIONS = '/admin/promotions',
  ADMIN_LOGIN = '/admin-login',
  ADMIN_PRODUCT_EVENTS = '/admin/product-events',
  ADMIN_PRODUCT_RECOMMENDATION = '/admin/product-recommendation',
  ADMIN_PRODUCT_RECOMMENDATION_VIEW = '/admin/product-recommendation/:id',
  ADMIN_MANAGE_NOTIFICATIONS = '/admin/manage-notifications',
  APP_RECOMMENDATION = '/Recommendation/*',
  APP_REQUEST = '/Request/*',
  CHECK_EMAIL = '/auth/check-email/:id',
  CREATE_ACCOUNT = '/auth/createAccount',
  CREATE_PASSWORD = '/createPassword',
  CUSTOMER_DASHBOARD = '/dashboard',
  CUSTOMER_DASHBOARD_ALT = '/db/:accessToken',
  CUSTOMER_DASHBOARD_BENEFITS = '/dashboard/benefits',
  CUSTOMER_DASHBOARD_BENEFITS_ALT = '/db/:accessToken/benefits',
  CUSTOMER_DASHBOARD_HOW_IT_WORKS = '/dashboard/how-it-works',
  CUSTOMER_DASHBOARD_HOW_IT_WORKS_ALT = '/db/:accessToken/how-it-works',
  CUSTOMER_DASHBOARD_PROMOTIONS = '/dashboard/promotions',
  CUSTOMER_DASHBOARD_PROMOTIONS_ALT = '/db/:accessToken/promotions',
  CUSTOMER_DASHBOARD_WALLET = '/dashboard/wallet',
  CUSTOMER_DASHBOARD_WALLET_ALT = '/db/:accessToken/wallet',
  CUSTOMER_DETAILS = '/admin/customers/:id',
  ERROR = '/error',
  FEATURE_FLAGS = '/admin/feature-flags',
  FORGOT_PASSWORD = '/auth/forgotPassword',
  GET_APP = '/get-app',
  HOMEPAGE = '/',
  LANDING = '/auth/login',
  LOGIN = '/login',
  MY_PROFILE = '/auth/profile',
  OAUTH_VERIFY = '/oauth/verify',
  PAST_PRICING_REQUESTS = '/pricing-request/list',
  PAYMENT = '/offer/:offerId/payment',
  PAYMENT_SUBMITTED = '/offer/:offerId/payment-success',
  PLACE_OFFER = '/pricing-request/:id/offer',
  PLACE_OFFER_SUCCESS = '/placeOfferSuccess',
  PORTAL_LOGIN = '/portal-login',
  PRICING_REQUEST = '/admin/pricing-request',
  PRODUCT = '/product',
  PRODUCT_PAGE = '/product/:id',
  REDIRECT = '/redirect/:id',
  RESET_PASSWORD = '/resetPassword',
  RETAILER_APP_RECOMMENDATION = '/Retailer/Recommendation/*',
  RETAILER_APP_REQUEST = '/Retailer/Request/*',
  RETAILER_HOME = '/retailerHome',
  REVIEW_SELECT_OFFERS = '/pricing-request/:id/offers',
  SAVED_RETAILERS = '/saved-retailers',
  SET_RETAILER_PASSWORD = '/setRetailerPassword',
  SHOP = '/shop',
  STOREFRONT_LOGIN = '/sign-in',
  UI_SHELL_DEMO = '/ui-shelldemo',
  UNDER_CONSTRUCTION = '/under-construction',
  UX_SANDBOX = '/ux-sandbox',
  VERIFY_EMAIL = '/verify-email',
  VERSION = '/version',
  VIEW_DETAIL = '/admin/view-detail',
  VIEW_OFFERS = '/viewOffers',
  WEB_DELIVERABLE ='/web-deliverable',
}

export const PublicRoutes = [
  Routes.PAYMENT,
  Routes.PAYMENT_SUBMITTED,
  Routes.PLACE_OFFER,
  Routes.PLACE_OFFER_SUCCESS,
  Routes.REDIRECT,
  Routes.RETAILER_HOME,
  Routes.VERIFY_EMAIL,
  Routes.VERSION,
];

export const DashboardRoutes = [
  Routes.CUSTOMER_DASHBOARD,
  Routes.CUSTOMER_DASHBOARD_ALT,
  Routes.CUSTOMER_DASHBOARD_BENEFITS,
  Routes.CUSTOMER_DASHBOARD_BENEFITS_ALT,
  Routes.CUSTOMER_DASHBOARD_HOW_IT_WORKS,
  Routes.CUSTOMER_DASHBOARD_HOW_IT_WORKS_ALT,
  Routes.CUSTOMER_DASHBOARD_PROMOTIONS,
  Routes.CUSTOMER_DASHBOARD_PROMOTIONS_ALT,
  Routes.CUSTOMER_DASHBOARD_WALLET,
  Routes.CUSTOMER_DASHBOARD_WALLET_ALT,
];

