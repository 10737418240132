import { useState } from 'react';
import { Button, HSpacer, Modal, Text, VSpacer } from '@/components/DesignSystem';
import { DeliverableTemplateType } from '@shared/enums/DeliverableTemplateType';
import { Box, Link, Stack } from '@mui/material';
import { ApiRetailer } from '@api/interfaces';
import {
  BrandColorModal,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/BrandAssets/BrandColorModal';
import Check from '@mui/icons-material/Check';
import { RetailerConstants } from '@/constants/constant';

const contrastCheckerUrl = 'https://accessibleweb.com/color-contrast-checker/';

interface DeliverableTemplateModalProps {
  onClose: () => void,
  onSaveBrandColor: (brandColor: string | null) => void,
  onSaveTemplateType: (templateType: DeliverableTemplateType) => void,
  retailer: ApiRetailer,
}

export const DeliverableTemplateModal = ({
  onClose,
  onSaveBrandColor,
  onSaveTemplateType,
  retailer,
}: DeliverableTemplateModalProps) => {
  const [templateType, setTemplateType]
    = useState<DeliverableTemplateType | null>(retailer.templateType);
  const [showBrandColorModal, setShowBrandColorModal] = useState(false);
  const isValid = templateType !== (retailer.templateType);

  const ColorCircle = (
    <Box
      bgcolor={retailer.brandColor ?? 'transparent'}
      borderRadius="50%"
      height="32px"
      width="32px"
    />
  );

  const BrandColorSection = (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      {retailer.brandColor ? (
        <>
          <Stack alignItems="center" direction="row">
            {ColorCircle}
            <HSpacer size="4" />
            <Text category="body-large">
              Brand Color {retailer.brandColor}
            </Text>
          </Stack>
          <Link href={contrastCheckerUrl} rel="noopener" target="_blank">
            <Button
              testID="deliverable-template-modal-check-contrast-ratio-button"
              variant="outlined"
            >
              Check contrast ratio
            </Button>
          </Link>
        </>
      ) : (
        <>
          <Text category="body-large">
            No brand color exists
          </Text>
          <Button
            onClick={() => setShowBrandColorModal(true)}
            testID="deliverable-template-modal-add-brand-color-button"
          >
            Add Brand Color
          </Button>
        </>
      )}
    </Stack>
  );

  const TemplateSection = (
    <Stack direction="row" gap="24px" justifyContent="space-between">
      <Stack alignItems="center" gap="12px">
        <Text category="body-small">
          White text template
        </Text>
        <Stack
          alignItems="center"
          bgcolor={retailer.brandColor ?? RetailerConstants.defaultBrandColor}
          borderRadius="4px"
          height="152px"
          width="248px"
        >
          <VSpacer size="6" />
          <Box
            border="2px solid white"
            borderRadius="50%"
            height="56px"
            width="56px"
          />
          <VSpacer size="3" />
          <Text category="title-large" fontWeight={500}>
            Text here
          </Text>
          <VSpacer size="1" />
          <Text category="body-small">
            Text here
          </Text>
          <VSpacer size="7" />
        </Stack>
        <Button
          onClick={() => setTemplateType(DeliverableTemplateType.WhiteText)}
          testID="deliverable-template-modal-select-white-text-button"
          variant={templateType === DeliverableTemplateType.WhiteText ? 'contained' : 'outlined'}
        >
          {templateType === DeliverableTemplateType.WhiteText
            ? <>
              <Check sx={{ height: '18px', width: '18px' }} />
              <HSpacer size="3" />
              Selected
            </>
            : 'Select'}
        </Button>
      </Stack>
      <Stack alignItems="center" gap="12px">
        <Text category="body-small">
          Black text template
        </Text>
        <Stack
          alignItems="center"
          bgcolor={retailer.brandColor ?? RetailerConstants.defaultBrandColor}
          borderRadius="4px"
          height="152px"
          width="248px"
        >
          <VSpacer size="6" />
          <Box
            border="2px solid black"
            borderRadius="50%"
            height="56px"
            width="56px"
          />
          <VSpacer size="3" />
          <Text category="title-large" fontWeight={500} sx={{ color: 'black' }}>
            Text here
          </Text>
          <VSpacer size="1" />
          <Text category="body-small"  sx={{ color: 'black' }}>
            Text here
          </Text>
          <VSpacer size="7" />
        </Stack>
        <Button
          onClick={() => setTemplateType(DeliverableTemplateType.BlackText)}
          testID="deliverable-template-modal-select-black-text-button"
          variant={templateType === DeliverableTemplateType.BlackText ? 'contained' : 'outlined'}
        >
          {templateType === DeliverableTemplateType.BlackText
            ? <>
                <Check sx={{ height: '18px', width: '18px' }} />
                <HSpacer size="3" />
                Selected
              </>
            : 'Select'}
        </Button>
      </Stack>
    </Stack>
  );

  return (
    <>
      <Modal
        acceptButton={(props) => (
          <Button
            {...props}
            disabled={!isValid}
            onClick={() => {
              onClose();
              onSaveTemplateType(templateType!);
            }}
            testID="deliverable-template-modal-save-button"
          >
            Save
          </Button>
        )}
        cancelButton={(props) => (
          <Button
            {...props}
            color="inherit"
            onClick={onClose}
            testID="deliverable-template-modal-cancel-button"
          >
            Cancel
          </Button>
        )}
        onClose={onClose}
        open
        padding={3}
        testID="deliverable-template-modal"
        title="Select Deliverable Template"
        width={560}
      >
        <Text category="body-medium">
          Ensure the brand color meets accessibility standards by verifying its contrast ratio.
          Use the <Link href={contrastCheckerUrl} rel="noopener" target="_blank">WCAG Online
          Contrast Checker</Link> to test your colors. Your text color should have a <strong>minimum
          contrast ratio of 4.5:1</strong> against the background for <strong>normal text
          and 3:1</strong> for large text to comply with WCAG AA guidelines. Adjust your
          colors as needed to achieve these ratios and ensure readability for all users.
        </Text>
        <VSpacer size="7" />
        <Text category="title-medium">
          Step 1 : Check contrast ratio
        </Text>
        <VSpacer size="3" />
        {BrandColorSection}
        <VSpacer size="8" />
        <Text category="title-medium">
          Step 2 : Select template
        </Text>
        <VSpacer size="7" />
        {TemplateSection}
      </Modal>
      {showBrandColorModal && (
        <BrandColorModal
          onClose={() => setShowBrandColorModal(false)}
          onSave={onSaveBrandColor}
        />
      )}
    </>
  );
};
