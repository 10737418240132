import { alpha, Components, Theme } from '@mui/material';
import { defaultTypography, Typography } from './typography';

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    tiny: true,
    giant: true,
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsSizeOverrides {
    xsmall: true,
  }
}

export const components = (theme: Theme): Components => ({
  MuiAppBar: {
    styleOverrides: {
      root: {
        height: "64px",
        boxShadow: "0px 0px",
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        borderRadius: "4px",
        padding: '0px',
      },
      endAdornment: {
        marginTop: "-5px",
      },
      option: {
        marginTop: "16px",
        marginBottom: "16px",
      },
    },
  },
  MuiBadge: {
    styleOverrides: {
      standard: {
        minWidth: 16,
        height: 16,
        paddingLeft: 0.5,
        paddingRight: 0.5,
      },
      dot: {
        minWidth: 6,
        height: 6,
      },
    },
  },
  MuiBottomNavigationAction: {
    styleOverrides: {
      root: {
        height: "80px",
        marginLeft: "4px",
        marginRight: "4px",
        minWidth: "1px",
        '.MuiSvgIcon-root': {
          paddingTop: "10px",
        },
        '&.Mui-selected .MuiSvgIcon-root': {
          borderRadius: "16px",
          padding: "10px 26px 10px 26px",
        },
        '&.Mui-selected': {
          fontWeight: "500",
          letterSpacing: "0.5px",
          lineHeight: "16px",
        },
        ...Typography["label-medium"],
      },
      label: {
        paddingTop: "14px",
        '&.Mui-selected': {
          paddingTop: "4px",
          ...Typography["label-medium"],
        },
      },
    },
  },
  MuiBottomNavigation: {
    styleOverrides: {
      root: {
        height: "80px",
      },
    },
  },
  MuiButton: {
    defaultProps: {
      size: 'medium',
      variant: 'contained',
    },
    root: {
      classes: {
        buttonPadding: 0,
      },
    },
    styleOverrides: {
      sizeSmall: {
        borderRadius: 2,
        fontWeight: 500,
        fontSize: 12,
        padding: 8,
        height: 32,
        minWidth: 0,
        letterSpacing: 0.5,
        marginHorizontal: 4,
        textTransform: 'none',
      },
      sizeMedium: {
        borderRadius: 20,
        textFontWeight: defaultTypography.button?.fontWeight,
        textFontSize: 14,
        paddingHorizontal: 16,
        paddingVertical: 12,
        height: 40,
        minWidth: 0,
        letterSpacing: 0.85,
        marginHorizontal: 6,
        textTransform: 'none',
      },
      sizeLarge: {
        borderRadius: 24,
        fontWeight: defaultTypography.button?.fontWeight,
        fontSize: 16,
        paddingHorizontal: 12,
        paddingVertical: 12,
        height: 48,
        letterSpacing: 0.4,
        minWidth: 0,
        textTransform: 'none',
      },
      startIcon: {
        marginHorizontal: 0,
      },
      endIcon: {
        marginHorizontal: 0,
      },
    },
    variants: [
      {
        props: { size: 'tiny' },
        style: {
          borderRadius: 2,
          padding: 6,
          height: 24,
          minWidth: 0,
          margin: 0,
          marginHorizontal: 4,
          textTransform: 'none',
          ...Typography["tiny"],
        },
      },
      {
        props: { size: 'giant' },
        style: {
          borderRadius: 56,
          fontWeight: defaultTypography.button?.fontWeight,
          fontSize: 18,
          paddingHorizontal: 24,
          paddingVertical: 16,
          margin: 0,
          height: 56,
          letterSpacing: 0.5,
          minWidth: 0,
          textTransform: 'none',
          lineHeight: 1.33,
        },
      },
    ],
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: '4px',
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: '20px 28px 20px 28px',
      },
    },
  },
  MuiButtonGroup: {
    styleOverrides: {
      grouped: {
        minWidth: "102px",
        ...Typography["label-large"],
      },
    },
  },
  MuiContainer: {
    styleOverrides: {
      maxWidthXs: {
        [theme.breakpoints.up('xs')]: {
          maxWidth: 489 + 48, //Default: 444
        },
      },
      maxWidthSm: {
        [theme.breakpoints.up('sm')]: {
          maxWidth: 660 + 48, //Default: 600
        },
      },
      maxWidthMd: {
        [theme.breakpoints.up('md')]: {
          maxWidth: 990 + 48, //Default: 900
        },
      },
      maxWidthLg: {
        [theme.breakpoints.up('lg')]: {
          maxWidth: 1200, //Default: 1200
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: "8px",
        width: "fit-content",
      },
    },
    variants: [
      {
        props: { size: 'xsmall' },
        style: {
          '.MuiChip-label': {
            paddingLeft: '0px 6px',
          },
          height: '16px',
          textTransform: 'uppercase',
          ...Typography["label-small"],
        },
      },
    ],
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        backdropFilter: 'blur(5px)',
        borderRadius: '16px',
      },
      paper: {
        backgroundColor: theme.palette.background.default,
        backgroundImage: 'none',
        borderRadius: '16px',
      },
      paperFullWidth: {
        borderRadius: '16px',
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        margin: 0,
        padding: '0 16px 0 10px',
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        p: '16px 24px 24px',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: '0 24px 0',
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        borderRadius: "16px",
        height: "56px",
      },
      sizeMedium: {
        height: "56px",
      },
      sizeSmall: {
        height: "40px",
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        ...Typography["body-small"],
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        width: "40px",
        height: "40px",
      },
    },
  },
  // Styles for text field
  MuiFilledInput: {
    styleOverrides: {
      // Styles for Input
      multiline: {
        alignItems:"flex-start",
        padding: "16px",
        ".MuiInputAdornment-root": {
          "&.MuiInputAdornment-positionStart": {
            alignItems: "flex-end",
          },
          "&.MuiInputAdornment-positionEnd": {
            alignItems: "flex-start",
            marginTop: "-1px !important",
          },
        },
      },
      root: {
        padding: '0 0 0 4px',
        minHeight: '56px',
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        "&.Mui-focused.MuiInputBase-formControl:after": {
          transform: "translateY(1px)",
        },
        "&.Mui-error.MuiInputBase-formControl:after": {
          transform: "translateY(1px)",
        },
        "&.Mui-error.Mui-focused.MuiInputBase-formControl:after": {
        },
        ...Typography["body-large"],
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontWeight: "400 !important",
        padding: '0 0 0 6px',
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        width: "32px",
      },
      positionStart: {
        paddingBottom: "16px",
        paddingLeft: "12px",
      },
      positionEnd: {
        width: "48px",
      },
    },
  },
  // Styles for search box
  MuiInput: {
    styleOverrides: {
      root: {
        paddingLeft: "18px",
        paddingRight: "18px",
        borderRadius: "28px",
        height: "56px",
        "&.MuiInputBase-formControl:after": {
          borderBottom: "0px !important",
        },
        "&.MuiInputBase-formControl:before": {
          borderBottom: "0px !important",
        },
        ...Typography["body-large"],
      },
      input: {
        paddingLeft: "4px",
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        '.MuiListItemText-secondary': {
          ...Typography["body-medium"],
        },
        '.MuiListItemSecondaryAction-root': {
          paddingRight: "16px",
          ...Typography["title-small"],
        },
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        '.MuiListItemText-root span': {
          ...Typography["label-large"],
        },
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        marginRight: "-18px",
      },
    },
  },
  MuiListSubheader: {
    styleOverrides: {
      root: {
        ...Typography["title-small"],
        padding: "18px 0px 18px 28px",
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      root: {
        backdropFilter: 'none',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        minWidth: '308px',
        paddingLeft: '16px',
        paddingTop: '16px',
        paddingBottom: '16px',
        '&:hover': { backgroundColor: alpha('#fff', .02) },
      },
    },
  },
  MuiMobileStepper: {
    styleOverrides: {
      dot: {
        width: '12px',
        height: '12px',
        marginRight: '24px',
      },
      dots: {
        marginRight: '-24px',
      },
      progress: {
        height: '2px',
      },
    },
  },
  MuiModal: {
    styleOverrides: {
      root: {
        backdropFilter: 'blur(5px)',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        "& .MuiInputAdornment-positionStart": {
          paddingBottom: 0,
          paddingLeft: '14px',
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        minHeight: '56px',
      },
    },
  },
  MuiSnackbar: {
    styleOverrides: {
      root: {
        borderRadius: "4px",
        width: "400px",
      },
    },
  },
  MuiSnackbarContent: {
    styleOverrides: {
      root: {
        borderRadius: "4px",
        padding: "2px 16px 2px 16px",
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        ...Typography["title-small"],
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        borderCollapse: "separate",
        borderRadius: "4px",
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        ...Typography["label-large"],
      },
      body: {
        ...Typography["body-medium"],
      },
      root: {
        borderBottom: '1px solid #393939',
      },
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        padding: '28px 28px 32px',
      },
    },
  },
} as Components);
