import {
  Tier,
} from "@/pages/Admin/HierarchyOfRetailers/Retailer/RewardsPrograms/RewardsProgramModal";
import { Accordion, AccordionDetails, AccordionSummary, Stack } from "@mui/material";
import React, { useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { HSpacer, IconButton, Text, VSpacer } from "@/components/DesignSystem";
import { styled } from '@mui/material/styles';
import { formatCurrency } from "@shared/utilities";
import EditIcon from "@mui/icons-material/Edit";
import { DeleteOutlined } from "@mui/icons-material";
import { ApiCategory } from "@api/interfaces";
import {
  RewardsTierSegment,
} from "@/pages/Admin/HierarchyOfRetailers/Retailer/RewardsPrograms/RewardsTierSegment";

const RewardsTierAccordion = styled(Accordion)(() => {
  return {
    '&:before': {
      display: 'none',
    },
    '&.MuiAccordion-root': {
      backgroundColor: '#2a2a2a',
      borderRadius: '8px',
      boxShadow: 'none',
    },
    '.MuiAccordionDetails-root': {
      backgroundColor: "#2a2a2a",
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
    '.MuiAccordionSummary-root': {
      backgroundColor: '#2a2a2a',
      borderRadius: '8px',
      '&.Mui-expanded': {
        backgroundColor: '#2a2a2a',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
      },
    },

  };
});

interface RewardsTierProps {
  categories: ApiCategory[],
  categoryExclusions: {
    categoryIdsExcludedFromSegmentSpend?: string[],
    categoryIdsExcludedFromTierSpend?: string[],
  },
  editing?: boolean,
  rank: number,
  tier: Tier,
}

export const RewardsTier = ({
  categories,
  categoryExclusions,
  editing = false,
  rank,
  tier,
}: RewardsTierProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <RewardsTierAccordion disableGutters expanded={expanded}>
      <AccordionSummary onClick={() => setExpanded(!expanded)}>
        <Stack alignItems="center" direction="row" justifyContent="space-between" width={"100%"}>
          <Stack direction="row">
            <HSpacer size="3"/>
            <Text category="body-large">
              {rank}
            </Text>
            <HSpacer size="5"/>
            <Text category="body-large">
              {tier.name}
            </Text>
          </Stack>
          <Stack direction="row">
            <Stack width="160px">
              <Text category="body-small" sx={{ color: "#D8D8D8" }}>
                Min total spend
              </Text>
              <VSpacer size="1" />
              <Text category="body-large">
                {formatCurrency(tier.minimumDollars)}
              </Text>
            </Stack>
            <Stack width="160px">
              <Text category="body-small" sx={{ color: "#D8D8D8" }}>
                Min categories
              </Text>
              <VSpacer size="1" />
              <Text category="body-large">
                {tier.minimumSegments}
              </Text>
            </Stack>
            {editing && (
              <>
                <IconButton
                  color="primary"
                  testID={`${tier.name}-edit-button`}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  testID={`${tier.name}-delete-button`}
                >
                  <DeleteOutlined />
                </IconButton>
              </>
            )}
            <IconButton color="inherit" testID={`${tier.name}-expand-button`}>
              {expanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
            </IconButton>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack sx={{ paddingLeft: "35px" }}>
          {tier.segments.map((segment) => (
            <RewardsTierSegment
              categories={categories}
              categoryExclusions={categoryExclusions}
              key={segment.categoryId}
              segment={segment}
            />
          ))}
        </Stack>
      </AccordionDetails>
    </RewardsTierAccordion>
  );
};
