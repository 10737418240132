import React, { useState } from 'react';
import { Button as Button, Text } from '@/components/DesignSystem';
import UploadIcon from '@mui/icons-material/Upload';
import { Box, Stack, Typography } from '@mui/material';
import { ManufacturerApi } from '@/utilities/api/ManufacturerApi';
import { useMutation, useQueryClient } from 'react-query';
import { QueryKeys } from '@/constants/QueryKeys';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';

import { ToastMessages } from '@/constants/constant';
import { ApiManufacturer } from '@api/interfaces';
import ConfirmDialog from './ConfirmDialog';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ImageModal } from '@/pages/Admin/HierarchyOfRetailers/Retailer/BrandAssets/ImageModal';
import { getOriginalFileName } from '@shared/utilities';

const UploadImage = ({
  id,
  manufacturer,
}: {
  id: string;
  manufacturer: ApiManufacturer;
}) => {
  const [showConfirmRemoveLogoModal, setShowConfirmRemoveLogoModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const { openSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: uploadLogo, isLoading } = useMutation(
    (logo: string | null) => ManufacturerApi.update(id, { logo }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_MANUFACTURERS);
        await queryClient.invalidateQueries(QueryKeys.GET_MANUFACTURER_BY_ID);
        openSnackbar(ToastMessages.adminRetailerUploadLogoSuccess);
      },
      onError: () => {
        openSnackbar(ToastMessages.adminRetailerUploadLogoFailure);
      },
    },
  );

  const { mutate: deleteLogo, isLoading: isRemoveLogoLoading } = useMutation(
    () => ManufacturerApi.update(id, { logo: null }),
    {
      onSuccess: async () => {
        openSnackbar('Logo removed successfully');
        await queryClient.invalidateQueries(QueryKeys.GET_MANUFACTURERS);
        await queryClient.invalidateQueries(QueryKeys.GET_MANUFACTURER_BY_ID);
        setShowConfirmRemoveLogoModal(false);
      },
    },
  );

  return (
    <>
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        spacing={3}
        sx={{ padding: '20px' }}
      >
        <Stack spacing={1}>
          <Text category='h6'>Logo</Text>
        </Stack>
        <Box>
          {!manufacturer?.logo ? (
            <Button
              loading={isLoading}
              onClick={() => setShowImageModal(true)}
              startIcon={<UploadIcon />}
              testID='upload-logo-button'
              variant='outlined'
            >
              Upload Logo
            </Button>
          ) : (
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'
            >
              <Typography sx={{ marginRight: '28px' }}>
                {manufacturer.logo ? getOriginalFileName(manufacturer.logo) : ''}
              </Typography>
              <Button
                onClick={() => {
                  setShowConfirmRemoveLogoModal(true);
                }}
                startIcon={<DeleteOutlineIcon />}
                testID='delete-logo'
                variant='text'
              >
                Remove
              </Button>
            </Stack>
          )}
          {showConfirmRemoveLogoModal && (
            <ConfirmDialog
              cancelButtonText='No'
              confirmButtonText='Yes'
              isLoading={isRemoveLogoLoading}
              isOpen={showConfirmRemoveLogoModal}
              message="Are you sure you want to delete the logo?"
              onClose={() => setShowConfirmRemoveLogoModal(false)}
              onConfirm={() => deleteLogo()}
              title="Delete Logo?"
            />
          )}
        </Box>
      </Stack>
      {showImageModal && (
        <ImageModal
          imageFilename={manufacturer.logo}
          onClose={() => setShowImageModal(false)}
          onSave={(image) => uploadLogo(image)}
          title="Upload Logo"
        />
      )}
    </>
  );
};

export default UploadImage;