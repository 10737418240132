import {
  Segment,
} from "@/pages/Admin/HierarchyOfRetailers/Retailer/RewardsPrograms/RewardsProgramModal";
import { formatRewardsUoM, RewardsType, RewardsUom } from "@shared/enums";
import { roundToFixed } from "@shared/utilities";
import { Divider, Stack } from "@mui/material";
import { HSpacer, Text, VSpacer } from "@/components/DesignSystem";
import { DoDisturb, VisibilityOff } from "@mui/icons-material";
import React from "react";
import { ApiCategory } from "@api/interfaces";

interface RewardsTierSegmentProps {
  categories: ApiCategory[],
  categoryExclusions: {
    categoryIdsExcludedFromSegmentSpend?: string[],
    categoryIdsExcludedFromTierSpend?: string[],
  }
  segment: Segment,
}

export const RewardsTierSegment = ({
  categories,
  categoryExclusions: {
    categoryIdsExcludedFromSegmentSpend,
    categoryIdsExcludedFromTierSpend,
  },
  segment: {
    categoryId,
    hideOnDashboard,
    loyaltyUom,
    minimumHurdle,
    rewardsType,
    rewardsValue,
    subcategoryRewards,
    uom,
  },
} : RewardsTierSegmentProps) => {
  const Badge = ({ icon, value } : {
    icon: 'hide' | 'exclude',
    value: string,
  }) => {
    return (
      <Stack alignItems="center" direction="row">
        {icon === 'hide'
          ? <VisibilityOff color="error" sx={{ width: "12px", height: "12px" }} />
          : <DoDisturb color="error" sx={{ width: "12px", height: "12px" }} />}
        <HSpacer size="2" />
        <Text category="overline">
          {value}
        </Text>
      </Stack>
    );
  };

  const loyaltyText = (rewardsValue && loyaltyUom) && (
    rewardsType === RewardsType.Dollars
      ? `$${roundToFixed(rewardsValue, 4)} per ${
        loyaltyUom === RewardsUom.Dollars
          ? '$1'
          : formatRewardsUoM(1, loyaltyUom)
      }`
      : `${roundToFixed(rewardsValue * 100, 3)}% per $1`
  );
  const hurdleText = uom === RewardsUom.Dollars
    ? `$${roundToFixed(minimumHurdle, 2)}`
    : `${minimumHurdle} ${formatRewardsUoM(minimumHurdle, uom)}`;
  const categoryName = categories.find(({ id }) => id === categoryId)?.name;

  const excludeFromTierSpend = categoryIdsExcludedFromTierSpend?.includes(categoryId);
  const excludeFromSegmentSpend = categoryIdsExcludedFromSegmentSpend?.includes(categoryId);
  const showHideOnCrdBadge = hideOnDashboard
    || (
      !!subcategoryRewards
      && Object.values(subcategoryRewards).every((reward) => reward.hideOnDashboard)
    );
  return (
    <>
      <Divider />
      <VSpacer size="5" />
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack>
          <Text category="title-small">
            {categoryName}
          </Text>
          <VSpacer size="3" />
          {excludeFromTierSpend && (
            <Badge icon='exclude' value='exclude from min. spend' />
          )}
          {excludeFromSegmentSpend && (
            <Badge icon='exclude' value='exclude from min. categories' />
          )}
          {showHideOnCrdBadge && (
            <Badge icon='hide' value='hide on customer rewards dashboard' />
          )}
        </Stack>
        <Stack direction="row">
          <Stack width="140px">
            {!!rewardsType && rewardsValue !== null && (
              <>
                <Text category="body-small" sx={{ color: "#D8D8D8" }}>
                  Loyalty value
                </Text>
                <VSpacer size="1"/>
                <Text category="body-medium">
                  {loyaltyText}
                </Text>
              </>
            )}
          </Stack>
          <HSpacer size="5" />
          <Stack width="140px">
            <Text category="body-small" sx={{ color: "#D8D8D8" }}>
              Min hurdle
            </Text>
            <VSpacer size="1" />
            <Text category="body-medium">
              {hurdleText}
            </Text>
          </Stack>
        </Stack>
      </Stack>
      <VSpacer size="5" />
      <Stack sx={{ paddingLeft: "35px" }}>
        {!!subcategoryRewards && Object.entries(subcategoryRewards).map(
          ([subcategoryId, rewards]) => {
            const loyaltyText = rewards.rewardsType === RewardsType.Dollars
              ? `$${roundToFixed(rewards.rewardsValue!, 4)} per ${
                rewards.loyaltyUom === RewardsUom.Dollars
                  ? '$1'
                  : formatRewardsUoM(1, rewards.loyaltyUom)
              }`
              : `${roundToFixed(rewards.rewardsValue! * 100, 3)} %`;
            const subcategoryName = categories.find(({ id }) => id === subcategoryId)?.name;

            const excludeFromTierSpend = !!categoryIdsExcludedFromTierSpend?.includes(
              subcategoryId,
            );
            const excludeFromSegmentSpend = !!categoryIdsExcludedFromSegmentSpend?.includes(
              subcategoryId,
            );

            return (
              <Stack key={subcategoryId}>
                <Divider />
                <VSpacer size="5" />
                <Stack alignItems="center" direction="row" justifyContent="space-between">
                  <Stack>
                    <Text category="title-small">
                      {subcategoryName}
                    </Text>
                    {excludeFromTierSpend && (
                      <Badge icon='exclude' value='exclude from min. spend' />
                    )}
                    {excludeFromSegmentSpend && (
                      <Badge icon='exclude' value='exclude from min. categories' />
                    )}
                    {rewards.hideOnDashboard && (
                      <Badge icon='hide' value='hide on customer rewards dashboard' />
                    )}
                  </Stack>
                  <Stack width="296px">
                    <Text category="body-small" sx={{ color: "#D8D8D8" }}>
                      Loyalty value
                    </Text>
                    <VSpacer size="1" />
                    {!!rewards.rewardsType && rewards.rewardsValue !== null && (
                      <Text category="body-medium">
                        {loyaltyText}
                      </Text>
                    )}
                  </Stack>
                </Stack>
                <VSpacer size="5" />
              </Stack>
            );
          })}
      </Stack>
    </>
  );
};
