import PartyPopperIcon from '@/assets/icons/party_popper.png';
import TrophyIcon from '@/assets/icons/trophy_icon.svg';
import WavingHandIcon from '@/assets/icons/waving_hand.png';
import PoweredByGrowersLogo from '@/assets/logos/black/powered_by_growers.svg';
import {
  Button,
  Card,
  Dialog,
  HSpacer,
  IconButton,
  Text,
  TextBadge,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import { AppConfig } from '@/constants/AppConfig';
import { QueryKeys } from '@/constants/QueryKeys';
import { Routes } from '@/constants/Routes';
import { useDashboard } from '@/hooks/useDashboard';
import { TierBenefitsModal } from '@/pages/CustomerDashboard/Benefits/TierBenefitsModal';
import {
  DashboardData,
  ResolvedOrderTransaction,
  ResolvedPromotion,
  ResolvedRewardsProgram,
} from '@/pages/CustomerDashboard/interfaces';
import { LinkedAccountsModal } from '@/pages/CustomerDashboard/LinkedAccountsModal';
import { MyProgressModal } from '@/pages/CustomerDashboard/MyProgress/MyProgressModal';
import { ProgramExclusionsDisclaimer } from '@/pages/CustomerDashboard/ProgramExclusionsDisclaimer';
import { PromotionCard } from '@/pages/CustomerDashboard/Promotions/PromotionCard';
import { PromotionDetailsModal } from '@/pages/CustomerDashboard/Promotions/PromotionDetailsModal';
import { RewardsActivityCard } from '@/pages/CustomerDashboard/RewardsActivity/RewardsActivityCard';
import {
  RewardsActivityModal,
} from '@/pages/CustomerDashboard/RewardsActivity/RewardsActivityModal';
import { TierImage } from '@/pages/CustomerDashboard/TierImage';
import { TierProgressBar } from '@/pages/CustomerDashboard/TierProgressBar';
import { TotalSpendProgressBar } from '@/pages/CustomerDashboard/TotalSpendProgressBar';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { DashboardApi } from '@/utilities/api/DashboardApi';
import { ApiRetailer, ApiUser } from '@api/interfaces';
import { ApiFarmerRewards } from '@api/interfaces/ApiFarmerRewards';
import { ChevronRight, EmojiEvents, Wallet } from '@mui/icons-material';
import Check from '@mui/icons-material/Check';
import Email from '@mui/icons-material/Email';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Phone from '@mui/icons-material/Phone';
import Star from '@mui/icons-material/Star';
import {
  alpha,
  Avatar,
  Box,
  ButtonBase,
  CircularProgress,
  Container,
  Divider,
  Stack,
  useTheme,
} from '@mui/material';
import { GrowersContactInfo } from '@shared/enums';
import {
  buildRequirementTextForTier,
  formatPhoneNumber,
  getJsDateFromString,
  removeWhiteSpace,
} from '@shared/utilities';
import { getAccountName } from '@shared/utilities/UserUtilities';
import { Modify } from '@shared/utilities/UtilityTypes';
import { sortBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  formatAmount,
  formatDate,
  formatPoints,
  getProgramInfo,
  useDashboardNavigate,
} from './helpers';

const cardStyle = {
  bgcolor: 'white',
  borderRadius: '8px',
  boxShadow: '0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);',
  padding: '0px',
};
const cardContentStyle = {
  p: "16px",
  "&:last-child": {
    pb: "16px",
  },
};
const darkenedSectionStyle = {
  background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.03) 100%), #FFF',
};
export const buttonHoverStyle = {
  '&:hover': { bgcolor: 'transparent', borderColor: 'inherit' },
};

export const defaultDashboardData: DashboardData = {
  categories: [],
  farmer: {} as ApiUser,
  linkedAccounts: [],
  manufacturers: [],
  orders: [],
  program: {} as ResolvedRewardsProgram,
  promotions: [],
  retailer: {} as Modify<ApiRetailer, { brandColor: string }>,
  rewards: {} as ApiFarmerRewards,
  wallets: [],
};

export const CustomerDashboard = () => {
  const theme = useTheme();
  const { openSnackbar } = useSnackbar();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('accessToken') ?? params.accessToken;
  const navigate = useNavigate();
  const navigateToPage = useDashboardNavigate();
  const [showBalanceModal, setShowBalanceModal] = useState(false);
  const [showRewardsActivityModal, setShowRewardsActivityModal] = useState(false);
  const [showPromotionDetailsModal, setShowPromotionDetailsModal] = useState(false);
  const [showTierBenefitsModal, setShowTierBenefitsModal] = useState(false);
  const [showLinkedAccountsModal, setShowLinkedAccountsModal] = useState(false);
  const [showMyProgressModal, setShowMyProgressModal] = useState(false);
  const [selectedPromotion, setSelectedPromotion] = useState<ResolvedPromotion | null>(null);

  const handleOnError = () => {
    navigate(Routes.HOMEPAGE);
    openSnackbar('Could not retrieve dashboard');
  };

  const { data: dashboardData } = useDashboard(accessToken);

  const { data: allOrders } = useQuery<ResolvedOrderTransaction[]>(
    [QueryKeys.GET_DASHBOARD_ORDERS, accessToken],
    () => DashboardApi.listAllOrders(accessToken!) as Promise<ResolvedOrderTransaction[]>,
    {
      enabled: !!accessToken && showRewardsActivityModal,
      onError: handleOnError,
    },
  );

  const {
    categories,
    farmer,
    linkedAccounts,
    manufacturers,
    orders,
    rewards,
    program,
    promotions,
    retailer,
    wallets,
  } = dashboardData ?? defaultDashboardData;

  const {
    areAllCategoriesHurdled,
    currentTier,
    hasTierGraphics,
    isReachedFinalTier,
    isTierSpendHurdled,
    nextTier,
    numCategoriesToHurdle,
    relevantTier,
    spendToHurdle,
  } = useMemo(() => (
    getProgramInfo(program, rewards, !dashboardData)
  ), [dashboardData, program, rewards]);

  const hasExclusions = !!program.categoryIdsExcludedFromTierSpend.length
    || !!program.categoryIdsExcludedFromSegmentSpend.length;

  const progressToNextTierBannerText = useMemo(() => {
    if (!dashboardData) {
      return '';
    }
    const pluralizedCategory = numCategoriesToHurdle > 1 ? 'categories' : 'category';
    const formattedSpendToHurdle = formatAmount(spendToHurdle);
    if (numCategoriesToHurdle && spendToHurdle) {
      return removeWhiteSpace(`
        ${formattedSpendToHurdle} and ${numCategoriesToHurdle} ${pluralizedCategory}
        remaining to reach next tier
      `);
    } else if (numCategoriesToHurdle) {
      return `${numCategoriesToHurdle} ${pluralizedCategory} remaining to reach next tier`;
    } else if (spendToHurdle) {
      return `${formattedSpendToHurdle} remaining to reach next tier`;
    } else {
      return 'You did it! Final tier complete.';
    }
  }, [dashboardData, numCategoriesToHurdle, spendToHurdle]);

  const howToReachNextTierText = useMemo(() => {
    return buildRequirementTextForTier(relevantTier, isReachedFinalTier, false);
  }, [isReachedFinalTier, nextTier, program]);

  const programStartJsDate = program.startDate
    ? getJsDateFromString(program.startDate)
    : undefined;
  const programEndJsDate = program.endDate
    ? getJsDateFromString(program.endDate)
    : undefined;
  const programDateRange = (programStartJsDate && programEndJsDate)
    ? `${formatDate(programStartJsDate)} to ${formatDate(programEndJsDate)}`
    : '';

  const campaignImageUrl = program.campaignImage && (
    `${AppConfig.staticImageHost}/${program.campaignImage}`
  );

  const pointsBalance = useMemo(() => {
    if (!wallets || !dashboardData) {
      return 0;
    }
    return wallets.reduce((acc, wallet) => acc + wallet.balance, 0);
  }, [dashboardData, wallets]);

  const formattedPoints = formatPoints(pointsBalance);

  if (!dashboardData) {
    return (
      <Container>
        <Stack alignItems="center">
          <VSpacer size="14" />
          <CircularProgress />
        </Stack>
      </Container>
    );
  }

  const Header = (
    <Stack bgcolor={retailer.brandColor} color="white" height="140px" p="16px">
      <Stack alignItems="center" height="64px" justifyContent="center">
        <Text category="label-medium" textAlign="center">
          {retailer.name.toUpperCase()}
        </Text>
      </Stack>
      {!!campaignImageUrl && (
        <Stack alignItems="center" height="76px">
          <img
            height="60px"
            src={campaignImageUrl}
            style={{ objectFit: 'contain' }}
            width="360px"
          />
        </Stack>
      )}
    </Stack>
  );

  const ProgramInfo = (
    <Stack direction="row" justifyContent="space-between" p="16px" sx={darkenedSectionStyle}>
      <Stack>
        <Text category="title-medium">
          {program.name}
        </Text>
        <Text category="body-small">
          {programDateRange}
        </Text>
      </Stack>
      <Button
        onClick={() => navigateToPage(Routes.CUSTOMER_DASHBOARD_HOW_IT_WORKS, accessToken)}
        sx={{ color: retailer.brandColor, ...buttonHoverStyle }}
        testID="dashboard-how-it-works-button"
        variant="text"
      >
        How it works
      </Button>
    </Stack>
  );

  const FarmerGreeting = (
    <Stack direction="row">
      <Text fontSize="18px" fontWeight={400}>
        Hi, {getAccountName(farmer)}
      </Text>
      <HSpacer size="2" />
      <img
        src={WavingHandIcon}
        style={{ height: '20px', transform: 'scaleX(-1)', width: '20px' }}
      />
    </Stack>
  );

  const BalanceCard = (
    <Card
      cardContentStyle={{ padding: 0 }}
      sx={{
        bgcolor: '#141414',
        borderRadius: '4px',
        color: '#FEFEFE',
        height: '44px',
        padding: '12px 16px',
      }}
      testID="dashboard-balance-card"
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack alignItems="center" direction="row">
          <Star sx={{ color: '#F1BC42' }} />
          <HSpacer size="3" />
          <Text category="title-medium">
            Total points: {formattedPoints}
          </Text>
        </Stack>
        <IconButton
          onClick={() => setShowBalanceModal(true)}
          sx={{ color: '#FEFEFE', height: '20px', width: '20px' }}
          testID="dashboard-balance-card-info-button"
        >
          <InfoOutlined />
        </IconButton>
      </Stack>
    </Card>
  );

  const StatusCard = !!currentTier && (
    <Card
      cardContentStyle={cardContentStyle}
      sx={cardStyle}
      testID="dashboard-status-card"
    >
      <Stack direction="row">
        {hasTierGraphics && (
          <>
            <TierImage hasTierGraphics={hasTierGraphics} tier={currentTier} />
            <HSpacer size="3" />
          </>
        )}
        <Stack>
          <Text category="overline">
            CURRENT STATUS
          </Text>
          <VSpacer size="3" />
          <Text fontSize="28px" fontWeight={500}>
            {currentTier.name}
          </Text>
        </Stack>
      </Stack>
      <VSpacer size="4" />
      <Button
        fullWidth
        onClick={() => setShowTierBenefitsModal(true)}
        sx={{
          borderColor: '#D9D9D9',
          p: '10px 16px',
          ...buttonHoverStyle,
        }}
        testID="dashboard-status-card-benefits-button"
        variant="outlined"
      >
        <Avatar sx={{ bgcolor: 'black', height: '18px', width: '18px' }}>
          <img src={TrophyIcon} style={{ height: '12px', width: '12px' }}/>
        </Avatar>
        <HSpacer size="3" />
        {currentTier.name} Benefits
      </Button>
    </Card>
  );

  const BenefitsCard = (
    <ButtonBase
      onClick={() => navigateToPage(Routes.CUSTOMER_DASHBOARD_BENEFITS, accessToken)}
      sx={{ width: '100%' }}
    >
      <Card
        cardContentStyle={cardContentStyle}
        sx={[cardStyle, { paddingRight: '24px', width: '100%' }]}
        testID="dashboard-benefits-card"
      >
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Stack alignItems="center" direction="row">
            <EmojiEvents sx={{ color: retailer.brandColor }} />
            <HSpacer size="5" />
            <Text fontSize="18px" fontWeight={400}>
              Program Benefits
            </Text>
          </Stack>
          <ChevronRight sx={{ color: '#525252' }} />
        </Stack>
      </Card>
    </ButtonBase>
  );

  const WalletCard = (
    <ButtonBase
      onClick={() => navigateToPage(Routes.CUSTOMER_DASHBOARD_WALLET, accessToken)}
      sx={{ width: '100%' }}
    >
      <Card
        cardContentStyle={cardContentStyle}
        sx={[cardStyle, { paddingRight: '24px', width: '100%' }]}
        testID="wallet-card"
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack alignItems="center" direction="row">
            <Wallet sx={{ color: retailer.brandColor }} />
            <HSpacer size="5" />
            <Text fontSize="18px" fontWeight={400}>Wallet</Text>
          </Stack>
          <Stack alignItems="center" direction="row">
            <Text fontSize="16px" fontWeight={500} mr="10px">
              {formattedPoints} point{formattedPoints === '1' ? '' : 's'}
            </Text>
            <ChevronRight sx={{ color: '#525252' }} />
          </Stack>
        </Stack>
      </Card>
    </ButtonBase>
  );

  const StatusSection = (
    <Stack p="16px" sx={darkenedSectionStyle}>
      {FarmerGreeting}
      {linkedAccounts.length > 0 && (
        <>
          <VSpacer size="3" />
          <TextLink
            category="label-medium"
            onClick={() => setShowLinkedAccountsModal(true)}
            sx={{ color: '#818B15' }}
            testID="dashboard-linked-accounts-textlink"
          >
            Linked accounts ({linkedAccounts.length})
          </TextLink>
        </>
      )}
      <VSpacer size="5" />
      {BalanceCard}
      {!!currentTier && (
        <>
          <VSpacer size="5" />
          {StatusCard}
        </>
      )}
      <VSpacer size="5" />
      {WalletCard}
      <VSpacer size="5" />
      {BenefitsCard}
      <VSpacer size="4" />
    </Stack>
  );

  const TotalSpendSummary = (
    <Card
      cardContentStyle={{
        padding: '12px 16px 16px 16px',
        '&:last-child': { paddingBottom: '16px' },
      }}
      elevation={0}
      testID="current-spend-card"
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Text category="title-medium">
          Current spend
        </Text>
        {isTierSpendHurdled ? (
          <TextBadge text="COMPLETE" />
        ) : (
          <Stack alignItems="center">
            <Text category="body-medium">
              {formatAmount(relevantTier.minimumDollars - rewards.qualifyingTotalSpend)} remaining
            </Text>
          </Stack>
        )}
      </Stack>
      <VSpacer size="4" />
      <TotalSpendProgressBar
        minimumDollars={relevantTier.minimumDollars}
        totalSpend={rewards.qualifyingTotalSpend}
      />
      <VSpacer size="2" />
      <Stack direction="row" justifyContent="flex-end">
        <Text category="body-medium">
          Goal: {formatAmount(relevantTier.minimumDollars)}
        </Text>
      </Stack>
      {!isTierSpendHurdled && (
        <>
          <VSpacer size="4" />
          <Text category="body-xsmall" sx={{ color: '#525252' }}>
            This is the total amount spent across all categories that qualify for this tier.
          </Text>
        </>
      )}
    </Card>
  );

  const numSegments = relevantTier.minimumSegments;
  let chipWidth: string;
  if (numSegments <= 2) {
    chipWidth = '164px';
  } else if (numSegments === 4) {
    chipWidth = '77px';
  } else {
    chipWidth = '106px';
  }
  const hurdled = new Array(relevantTier.minimumSegments - numCategoriesToHurdle).fill(true);
  const notHurdled = new Array(numCategoriesToHurdle).fill(false);
  const CategoriesHurdledChips = (
    <Stack direction="row" flexWrap="wrap" gap="16px" justifyContent="center">
      {[...hurdled, ...notHurdled].map((isHurdled, i) => (
        <Stack
          alignItems="center"
          bgcolor={isHurdled ? '#237804' : theme.palette.grey[300]}
          borderRadius="4px"
          flexGrow={1}
          justifyContent="center"
          key={i}
          maxWidth={chipWidth}
          minWidth="77px"
          p="6px"
          width={chipWidth}
        >
          <Check sx={{ color: 'white' }}/>
        </Stack>
      ))}
    </Stack>
  );

  const CategoriesHurdledSummary = (
    <Card
      cardContentStyle={{
        padding: '12px 16px 16px 16px',
        '&:last-child': { paddingBottom: '16px' },
      }}
      elevation={0}
      testID="current-spend-card"
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Text category="title-medium">
          Categories hurdled
        </Text>
        {areAllCategoriesHurdled && (
          <>
            <HSpacer size="3" />
            <TextBadge text="COMPLETE" />
          </>
        )}
      </Stack>
      <VSpacer size="5" />
      {CategoriesHurdledChips}
    </Card>
  );

  const ProgressSummary = (
    <Stack>
      {!!relevantTier.minimumDollars && TotalSpendSummary}
      {(!!relevantTier.minimumDollars && !!relevantTier.minimumSegments) && (
        <VSpacer size="4" />
      )}
      {!!relevantTier.minimumSegments && CategoriesHurdledSummary}
    </Stack>
  );

  const TierGoal = (
    <Card
      cardContentStyle={{
        padding: '16px 16px 12px 16px',
        '&:last-child': { paddingBottom: '12px' },
      }}
      elevation={0}
      testID="tier-goal-card"
    >
      <Text category="overline">
        TO {isReachedFinalTier ? `MAINTAIN ${currentTier!.name}` : `REACH ${nextTier!.name}`}
      </Text>
      <VSpacer size="1" />
      <Text category="body-large">
        {howToReachNextTierText}
      </Text>
    </Card>
  );

  const ViewButton = ({ onClick, testID }: {
    onClick: () => void,
    testID: string,
  }) => (
    <Button
      onClick={onClick}
      sx={{
        background: 'none !important',
        border: `1px solid ${retailer.brandColor} !important`,
        color: retailer.brandColor,
      }}
      testID={testID}
      variant="outlined"
    >
      <Text category="label-large" sx={{ padding: '0px 8px' }}>
        View
      </Text>
    </Button>
  );

  const programStartYear = getJsDateFromString(program.startDate).getFullYear();
  const programEndYear = getJsDateFromString(program.endDate).getFullYear();
  const yearDiff = programEndYear - programStartYear;
  const yearText = yearDiff === 0
    ? programEndYear + 1 : `${programStartYear + yearDiff}-${programEndYear + yearDiff}`;
  const ProgressSection = (
    <Stack p="24px 16px 0px 16px">
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Text category="headline-medium">
          My Progress
        </Text>
        <ViewButton
          onClick={() => setShowMyProgressModal(true)}
          testID="dashboard-my-progress-button"
        />
      </Stack>
      <VSpacer size="3" />
      <Text category="body-medium" sx={{ color: theme.palette.grey[800] }}>
        Progress towards {rewards.rankOverride !== null ? 're-' : ''}earning your loyalty status
        for the {yearText} program year
      </Text>
      {rewards.earnedRank !== null && (
        <>
          <VSpacer size="5" />
          <Stack alignItems="center">
            <TierProgressBar
              currentRank={rewards.earnedRank}
              tierNames={program.tiers.map(({ name }) => name)}
            />
          </Stack>
          <VSpacer size="5" />
          <Stack alignItems="center">
            <Stack
              alignItems="center"
              bgcolor={isReachedFinalTier ? undefined : alpha(theme.palette.success.main, .12)}
              borderRadius="12px"
              direction="row"
              maxWidth="fit-content"
              p="4px 12px"
            >
              {isReachedFinalTier && (
                <>
                  <img src={PartyPopperIcon} style={{ height: '18px', width: '18px' }}/>
                  <HSpacer size="3"/>
                </>
              )}
              <Text category={isReachedFinalTier ? 'label-large' : 'body-small'}>
                {progressToNextTierBannerText}
              </Text>
            </Stack>
          </Stack>
        </>
      )}
      <VSpacer size="5" />
      {TierGoal}
      <VSpacer size="4" />
      {ProgressSummary}
      {hasExclusions && (
        <>
          <VSpacer size="5" />
          <ProgramExclusionsDisclaimer
            brandColor={retailer.brandColor}
            categories={categories}
            segmentSpendCategoryExclusionIds={program.categoryIdsExcludedFromSegmentSpend}
            tierSpendCategoryExclusionIds={program.categoryIdsExcludedFromTierSpend}
          />
        </>
      )}
    </Stack>
  );

  const RewardsActivitySection = (
    <Stack p="16px">
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack>
          <Text category="headline-medium">
            Rewards Activity
          </Text>
        </Stack>
        <ViewButton
          onClick={() => setShowRewardsActivityModal(true)}
          testID="dashboard-rewards-activity-button"
        />
      </Stack>
      <VSpacer size="3" />
      <Text category="body-medium" sx={{ color: theme.palette.grey[800] }}>
        Points earned & redeemed
      </Text>
      <VSpacer size="5" />
      <Divider />
      <Stack divider={<Divider />}>
        {sortBy(orders, ({ orderDate }) => -1 * orderDate.getTime()).slice(0, 3).map((order) => {
          let customer: ApiUser | undefined = undefined;
          if (linkedAccounts.length > 0) {
            customer = linkedAccounts.find((account) => (
              account.customerId === order.farmerId
            ))?.customer;
            if (!customer) {
              customer = farmer;
            }
          }
          return (
            <RewardsActivityCard customer={customer} key={order.id} order={order} />
          );
        })}
      </Stack>
      <Divider />
    </Stack>
  );

  const PromotionSection = (
    <Stack>
      <Stack p="16px">
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Stack>
            <Text category="headline-medium">
              Promotions
            </Text>
            <VSpacer size="1" />
            <Text category="body-medium" sx={{ color: theme.palette.grey[800] }}>
              Exclusive offers for our loyal customers
            </Text>
          </Stack>
          <ViewButton
            onClick={() => navigateToPage(Routes.CUSTOMER_DASHBOARD_PROMOTIONS, accessToken)}
            testID="view-promotions-button"
          />
        </Stack>
      </Stack>
      <VSpacer size="5" />
      <Box bgcolor={retailer.brandColor} minHeight="302px" overflow="auto" px="16px" py="24px">
        <Stack direction="row" height="100%">
          <Stack direction="row" gap="16px" height="100%">
            {sortBy(
              promotions.filter((promotion) => promotion.showInDashboardCarousel),
              ({ endDate }) => getJsDateFromString(endDate).getTime(),
            ).map((promotion) => (
              <PromotionCard
                key={promotion.id}
                onClickDetails={() => {
                  setSelectedPromotion(promotion);
                  setShowPromotionDetailsModal(true);
                }}
                promotion={promotion}
              />
            ))}
          </Stack>
          <Box height="100%" minWidth="16px" />
        </Stack>
      </Box>
    </Stack>
  );

  const Disclaimer = (
    <Stack px="16px">
      <Text category="body-medium" sx={{ color: '#141414' }}>
        Point values are per $1 spent or per unit of measure purchased. See promotion details.
      </Text>
      <VSpacer size="5" />
      <Text category="body-medium" sx={{ color: '#141414' }}>
        Loyalty points are not earned on miscellaneous fees, taxes, surcharges, etc.
      </Text>
    </Stack>
  );

  const Footer = (
    <Stack alignItems="center" p="32px 16px 24px" sx={darkenedSectionStyle}>
      <img src={PoweredByGrowersLogo} style={{ height: '42px' }} />
      <VSpacer size="7" />
      <Text category="title-small">
        Need help? Contact GROWERS support.
      </Text>
      <Stack direction="row">
        <Button
          href={`tel:${GrowersContactInfo.supportPhone}`}
          sx={{ color: retailer.brandColor, ...buttonHoverStyle }}
          testID="dashboard-contact-telephone-button"
          variant="text"
        >
          <Phone sx={{ height: '18px', width: '18px' }} />
          <HSpacer size="2" />
          {formatPhoneNumber(GrowersContactInfo.supportPhone, 'paren')}
        </Button>
        <Button
          href={`mailto:${GrowersContactInfo.supportEmail}`}
          sx={{ color: retailer.brandColor, ...buttonHoverStyle }}
          testID="dashboard-contact-email-button"
          variant="text"
        >
          <Email sx={{ height: '18px', width: '18px' }} />
          <HSpacer size="2" />
          {GrowersContactInfo.supportEmail}
        </Button>
      </Stack>
      <VSpacer size="7" />
      <TextLink
        category="body-small"
        href={GrowersContactInfo.termsOfUseLink}
        rel="noopener noreferrer"
        sx={{ color: '#525252', textDecoration: 'underline' }}
        target="_blank"
        testID="dashboard-terms-and-conditions-link"
      >
        Terms & Conditions
      </TextLink>
    </Stack>
  );

  const BalanceModal = (
    <Dialog
      cancelButton={(props) => (
        <Button
          onClick={() => setShowBalanceModal(false)}
          sx={{
            border: `1px solid ${retailer.brandColor}`,
            color: retailer.brandColor,
            p: '10px 16px',
          }}
          variant="outlined"
          {...props}
        >
          Close
        </Button>
      )}
      onClose={() => setShowBalanceModal(false)}
      open
      showCloseButton={false}
      testID="dashboard-balance-modal"
    >
      <Stack alignItems="center">
        <InfoOutlined sx={{ color: retailer.brandColor }} />
        <VSpacer size="5" />
        <VSpacer size="1" />
        <Text category="headline-small">
          Balance
        </Text>
        <VSpacer size="5" />
        <Text category="body-medium">
          Your balance is the total amount of money available to be applied towards
          new qualifying orders.
        </Text>
      </Stack>
    </Dialog>
  );

  return  (
    <>
      <Stack
        bgcolor="white"
        maxWidth="599px"
        minHeight="100vh"
        minWidth="360px"
      >
        {Header}
        {ProgramInfo}
        <Divider />
        {StatusSection}
        <Divider />
        {ProgressSection}
        <VSpacer size="8" />
        {RewardsActivitySection}
        {!!promotions.length && (
          <>
            <VSpacer size="8" />
            {PromotionSection}
          </>
        )}
        <VSpacer size="8" />
        {Disclaimer}
        <VSpacer size="7" />
        <Divider />
        {Footer}
      </Stack>
      {showBalanceModal && BalanceModal}
      {showRewardsActivityModal && (
        <RewardsActivityModal
          farmer={farmer}
          linkedAccounts={linkedAccounts}
          onClose={() => setShowRewardsActivityModal(false)}
          orders={allOrders}
        />
      )}
      {showPromotionDetailsModal && !!selectedPromotion && accessToken && (
        <PromotionDetailsModal
          accessToken={accessToken}
          onClose={() => {
            setShowPromotionDetailsModal(false);
            setSelectedPromotion(null);
          }}
          promotionId={selectedPromotion.id}
          retailerName={retailer.name}
        />
      )}
      {showTierBenefitsModal && !!currentTier &&  (
        <TierBenefitsModal
          brandColor={retailer.brandColor}
          categories={categories}
          hasTierGraphics={hasTierGraphics}
          manufacturers={manufacturers}
          onClose={() => setShowTierBenefitsModal(false)}
          program={program}
          promotions={promotions}
          retailer={retailer}
          tier={currentTier}
        />
      )}
      {showLinkedAccountsModal && linkedAccounts.length > 0 && (
        <LinkedAccountsModal
          accounts={linkedAccounts}
          farmer={farmer}
          onClose={() => setShowLinkedAccountsModal(false)}
        />
      )}
      {showMyProgressModal && (
        <MyProgressModal
          brandColor={retailer.brandColor}
          categories={categories}
          customerId={farmer.id}
          isReachedFinalTier={isReachedFinalTier}
          nextTier={relevantTier}
          onClose={() => setShowMyProgressModal(false)}
          program={program}
          rewards={rewards}
        />
      )}
    </>
  );
};
