import { Button, Modal, Text } from '@/components/DesignSystem';
import { ImageInput } from '@/components/shared/ImageInput/ImageInput';
import { ImageRequirementsHeader } from '@/components/shared/ImageInput/ImageRequirementsHeader';
import { useMemo, useState } from 'react';
import { validateImage } from '@/pages/Admin/HierarchyOfRetailers/Locations/AddLocation';
import { AppConfig } from '@/constants/AppConfig';
import { sanitizedFileName } from '@shared/utilities';

interface ImageModalProps {
  acceptedFileTypes?: string,
  acceptedMimeTypes?: string,
  imageFilename: string | null,
  onClose: () => void,
  onSave: (image: string | null) => void,
  title: string,
}

export const ImageModal = ({
  acceptedFileTypes,
  acceptedMimeTypes,
  imageFilename,
  onClose,
  onSave,
  title,
}: ImageModalProps) => {
  const [file, setFile] = useState<File | null | undefined>();
  const [imageError, setImageError] = useState<string | null>(null);

  const isValueChanged = file !== undefined && !imageError;

  const imageUrl = useMemo(() => {
    if (file !== undefined) {
      return file && URL.createObjectURL(file as Blob);
    } else {
      return imageFilename && `${AppConfig.staticImageHost}/${imageFilename}`;
    }
  }, [file, imageFilename]);

  const handleSave = async () => {
    let imageBase64: string | null = null;
    let imageFilename: string | null = null;
    if (file) {
      imageBase64 = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result as string);
        };
        reader.readAsDataURL(file as Blob);
      });
      imageFilename = sanitizedFileName(file.name);
    }
    const image = imageBase64 && `${imageFilename}+${imageBase64}`;
    onSave(image);
    onClose();
  };

  const onChangeImage = async (newFile: File) => {
    try {
      await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const image = new Image();
          image.src = reader.result as string;
          image.onload = () => {
            const imageError = validateImage(newFile);
            if (imageError) {
              reject(imageError);
            }
            resolve(reader.result as string);
          };
        };
        reader.readAsDataURL(newFile as Blob);
      });
    } catch (error) {
      setImageError(error as string);
      return;
    }
    setFile(newFile);
    setImageError(null);
  };

  return (
    <Modal
      acceptButton={(props) => (
        <Button
          {...props}
          disabled={!isValueChanged}
          onClick={handleSave}
          testID="image-modal-save-button"
        >
          Save
        </Button>
      )}
      cancelButton={(props) => (
        <Button
          {...props}
          color="inherit"
          onClick={onClose}
          testID="image-modal-cancel-button"
        >
          Cancel
        </Button>
      )}
      onClose={onClose}
      open
      testID="image-modal"
      title={title}
    >
      {!!imageError && (
        <Text color="error">
          {imageError}
        </Text>
      )}
      <ImageRequirementsHeader fileType={acceptedFileTypes} />
      <ImageInput
        acceptedMimeTypes={acceptedMimeTypes}
        imageSrc={imageUrl}
        onChangeImage={onChangeImage}
        onRemove={() => setFile(null)}
      />
    </Modal>
  );
};
