import { Button, Card, Chip, HSpacer, Switch, Text, VSpacer } from '@/components/DesignSystem';
import { Box, Stack } from '@mui/material';
import { ReactComponent as DefaultManufacturerIcon } from '@/assets/icons/manufacturer-icon.svg';
import React, { Fragment, useState } from 'react';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { SaveManufacturerModal } from './SaveEditManufacturer';
import { ApiManufacturer } from '@api/interfaces';
import ConfirmDialog from './ConfirmDialog';
import { useMutation, useQueryClient } from 'react-query';
import { ManufacturerApi } from '@/utilities/api/ManufacturerApi';
import { QueryKeys } from '@/constants/QueryKeys';
import { AppConfig } from '@/constants/AppConfig';
import { SXStyles } from "@/themes/variant-interfaces/SXStyles";
import { Avatar } from '@/components/DesignSystem/Avatar/Avatar';

const ManufacturerListItem = ({
  avatarSize = 48,
  manufacturer,
  onClick,
  showActions = true,
  showID = false,
}: {
  avatarSize?: number,
  manufacturer: ApiManufacturer;
  onClick?: () => void;
  showActions?: boolean;
  showID?: boolean;
}) => {
  const queryClient = useQueryClient();
  const [isEditManufacturer, setIsEditManufacturer] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isActive, setIsActive] = useState(manufacturer.isActive ?? false);
  
  const styles: SXStyles = {
    subline: {
      color: '#C9C6BE',
    },
    avatar: {
    },
  };

  const { mutate: updateManufacturerActivation, isLoading } = useMutation(
    async (isActive: boolean) => {
      return ManufacturerApi.update(manufacturer.id, {
        isActive: isActive,
      });
    },
    {
      onSuccess: async (manufacturer) => {
        await queryClient.invalidateQueries(QueryKeys.GET_MANUFACTURERS);
        setIsActive(manufacturer.isActive ?? false);
        setShowConfirmDialog(false);
      },
    },
  );

  const logoSrc = manufacturer.logo && `${AppConfig.staticImageHost}/${manufacturer.logo}`;

  return (
    <Fragment key={manufacturer.id}>
      <Card
        {...(!!onClick && { onClick })}
        cardContentStyle={{
          padding: '16px',
          '&:last-child': {
            paddingBottom: '16px',
          },
        }}
        sx={{ cursor: onClick ? 'pointer' : 'default', paddingBottom: "0px" }}
        testID='manufacturer-list-item'
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Stack alignItems={showID ? "flex" : "center"} direction="row">
            <Avatar
              alt={`${manufacturer.name} logo`}
              height={avatarSize}
              src={logoSrc ?? undefined}
              width={avatarSize}
            >
              {logoSrc ? null : (
                <DefaultManufacturerIcon height={avatarSize * .75} width={avatarSize * .75}/>
              )}
            </Avatar>
            <HSpacer size="5"/>
            <Stack spacing={1}>
              {showID
                ? <>
                    <Text category="headline-medium">{manufacturer?.name}</Text>
                    <Text category="body-medium" sx={styles.subline} >ID# {manufacturer.id}</Text>
                  </>
                : <Text category="body-xlarge">{manufacturer?.name}</Text>
              }
            </Stack>
          </Stack>
          {showActions ? (
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Stack style={{ marginTop: '7px' }}>
                <Switch
                  checked={isActive}
                  label={manufacturer.isActive ? 'Active' : 'Inactive'}
                  labelPlacement="start"
                  onClick={() => {
                    if (!isActive) {
                      updateManufacturerActivation(true);
                    } else {
                      setShowConfirmDialog(true);
                    }
                  }}
                  testID="partner-retailer-list-item-is-inactive"
                />
              </Stack>
              <HSpacer size="7"/>
              <Button
                onClick={() => setIsEditManufacturer(true)}
                startIcon={<EditOutlined/>}
                testID="Manufacturer-Edit-details-button"
                variant="outlined"
              >
                Edit
              </Button>
            </Box>
          ) : (
            <Chip
              color={isActive ? 'success' : undefined}
              label={isActive ? 'ACTIVE' : 'INACTIVE'}
              rounded
              sx={{ height: "auto", paddingLeft: "6px" }}
              testID={isActive ? `${manufacturer.id}-active` : `${manufacturer.id}-inactive`}
            />
          )}
        </Stack>
      </Card>
      <VSpacer size="3"/>
      {
        isEditManufacturer && (
          <SaveManufacturerModal
            close={() => setIsEditManufacturer(false)}
            manufacturer={manufacturer}
          />
        )}
      <ConfirmDialog
        isLoading={isLoading}
        isOpen={showConfirmDialog}
        message='Are you sure you want to set this manufacturer to inactive?'
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={() => {
          updateManufacturerActivation(false);
        }}
        title='Update Manufacturer'
      />
    </Fragment>
  );
};

export default ManufacturerListItem;
