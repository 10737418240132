import { Button, Input, Modal } from '@/components/DesignSystem';
import { useMemo, useState } from 'react';

interface WebsiteModalProps {
  onClose: () => void,
  onSave: (url: string) => void,
  url?: string | null,
}

export const WebsiteModal = ({
  onClose,
  onSave,
  url,
}: WebsiteModalProps) => {
  const [value, setValue] = useState(url ?? '');
  const isEditing = !!url;
  const isValueChanged = value !== (url ?? '');
  const properValue = value.includes('://') ? value : `https://${value}`;
  const isValidUrl = useMemo(() => {
    try {
      new URL(properValue);
      return true;
    } catch {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const isValid = isValueChanged && value.length && isValidUrl;

  return (
    <Modal
      acceptButton={(props) => (
        <Button
          {...props}
          disabled={!isValid}
          onClick={() => {
            onClose();
            onSave(properValue);
          }}
          testID="website-modal-save-button"
        >
          Save
        </Button>
      )}
      cancelButton={(props) => (
        <Button
          {...props}
          color="inherit"
          onClick={onClose}
          testID="website-modal-cancel-button"
        >
          Cancel
        </Button>
      )}
      onClose={onClose}
      open
      testID="website-modal"
      title={`${isEditing ? 'Edit' : 'Add'} Retailer Website`}
    >
      <Input
        label="Website URL"
        onChangeText={setValue}
        testID="website-modal-input"
        value={value} />
    </Modal>
  );
};
